import React, { createContext, useState, useEffect, useCallback } from 'react';
import {ToastContainer} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {AlertModal} from 'components/modals/AlertModal';
import {Toasty} from 'components/Toasty';

const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [toasts, setToasts] = useState([]);

    const showMessage = (title, body) => {
        setModalTitle(title);
        setModalBody(body);
        setShowModal(true);
    };

    const showToast = useCallback(function(title, message) {
        setToasts(toasts => [ ...toasts, { title: title, body: message }]);
    }, [setToasts]);

    const onExited = () => {
        setModalTitle('');
        setModalBody('');
    };

    const toastItems = toasts.map((toast, index) =>
        <Toasty key={index} title={toast.title} body={toast.body} />
    );

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(
                () => setToasts(toasts => toasts.slice(1)),
                3000
            );
            return () => clearTimeout(timer);
        }

    }, [toasts]);

    return (
        <NotificationContext.Provider value={{ showToast, showMessage }}>
            <ToastContainer className="p-5 position-fixed" position="bottom-center" style={{ zIndex: 10000}}>
                {toastItems}
            </ToastContainer>
            <AlertModal show={showModal} onHide={() => setShowModal(false)} onExited={onExited} title={modalTitle} body={modalBody} />
            {children}
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.any
};

export default NotificationContext;