import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const InsideSalesIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 97.61 97">
            <g>
                <path fill={primaryColour} d="M23.6,81.69h3.58c.87,0,1.57-.7,1.57-1.57h0v-18.5c0-.87-.71-1.57-1.57-1.57-.42,0-.82,.17-1.11,.46l-3.58,3.58c-.29,.3-.46,.69-.46,1.11v14.92c0,.87,.7,1.57,1.57,1.57Zm-10.81-7.25v5.68c0,.87,.7,1.57,1.57,1.57h3.58c.87,0,1.57-.7,1.57-1.57h0v-9.26c0-.87-.7-1.57-1.57-1.57-.42,0-.82,.17-1.11,.46l-1.96,1.96-1.62,1.62c-.29,.29-.46,.69-.46,1.11h0Zm20.05,7.25h3.58c.87,0,1.57-.7,1.57-1.57v-16.23c0-.42-.17-.82-.46-1.11l-3.58-3.58c-.61-.61-1.61-.61-2.22,0-.29,.29-.46,.69-.46,1.11v19.81c0,.87,.7,1.57,1.57,1.57h0Zm7.67-17.01v15.45c0,.87,.7,1.57,1.57,1.57h3.58c.87,0,1.57-.7,1.57-1.57v-19.03c0-.87-.7-1.57-1.57-1.57-.42,0-.82,.17-1.11,.46l-2.59,2.59-.99,.99c-.3,.29-.46,.69-.46,1.11h0Zm10.82,17.02h3.57c.87,0,1.57-.7,1.57-1.57v-28.27c0-.87-.71-1.57-1.57-1.57-.42,0-.82,.17-1.11,.46l-3.57,3.58c-.29,.29-.46,.69-.46,1.11v24.69c0,.87,.7,1.57,1.57,1.57h0Zm-.93-47.62c-.86,.08-1.5,.85-1.41,1.72,.03,.36,.19,.7,.45,.96l2.1,2.1,.03,.03-10.81,10.81c-.61,.61-1.61,.61-2.22,0l-6.76-6.76c-.61-.61-1.61-.61-2.22,0,0,0,0,0,0,0L8.9,63.57c-.61,.61-.61,1.61,0,2.22h0l2.53,2.53c.61,.61,1.61,.61,2.22,0l15.89-15.89c.61-.61,1.61-.61,2.22,0,0,0,0,0,0,0l6.76,6.76c.61,.61,1.61,.61,2.22,0l14.45-14.45c.61-.61,1.61-.61,2.22,0l.97,.97c.61,.61,1.61,.61,2.22,0,.26-.26,.42-.6,.45-.96l.95-9.9c.08-.86-.55-1.63-1.42-1.71-.1,0-.2,0-.3,0l-9.9,.95Z" />
                <path fill={secondaryColour} d="M84.33,54.33c0,4.58-3.72,8.3-8.3,8.3-4.58,0-8.3-3.72-8.3-8.3,0-4.58,3.72-8.3,8.3-8.3s8.3,3.72,8.3,8.3h0Z" />
                <path fill={secondaryColour} d="M76.46,64.36c-.15,0-.29,0-.43,0s-.29,0-.43,0c-8.16,0-14.77,6.61-14.77,14.77h0v2.56h30.41v-2.56c0-8.16-6.61-14.77-14.77-14.77Z" />
            </g>
        </svg>
    );
};

InsideSalesIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    InsideSalesIcon
};