import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
/* eslint-disable */
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // passes i18n down to react-i18next
    .use(initReactI18next)
    .init({
        lng: "en_gb",
        fallbackLng: 'en_gb',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false //   <---- this will do the magic
        },
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
        }
    });


export default i18n;