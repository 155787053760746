import React from 'react';

const Error404 = () => {
    return (
        <h1>
            404
        </h1>
    );
};

export {
    Error404
};