import { useState } from 'react';

const useValidatedField = (initialValue, validations = []) => {

    const [value, setValue] = useState(initialValue);
    const [errors, setErrors] = useState([]);

    const handleChange = (value) => {
        const errors = validate(validations, value);
        setValue(value);
        setErrors(errors);
    };

    return [value, handleChange, errors];
};

const validate = (validations, value) => {
    const errors = validations
        .map(validation => validation(value))
        .filter(validation => validation);

    if (errors.length > 1) {
        return [errors[0]];
    }

    return errors;
};

export default useValidatedField;