import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Switch = ({ checked, setChecked, disabled }  ) => {

    return (
        <Form.Check
            className="form-switch-2x"
            type="switch"
            label=""
            checked={checked}
            onChange={() => setChecked(!checked)}
            disabled={disabled}
        />
    );
};

Switch.propTypes = {
    checked: PropTypes.bool,
    setChecked: PropTypes.func,
    disabled: PropTypes.bool
};

export { Switch };