import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

let pie = null;

const HoursCard = ({ theme, core, setCore, detractors, setDetractors, supporting, setSupporting, ancillary, setAncillary }) => {

    const { t : translations } = useTranslation();
    const pieRef = useRef(null);

    const onPieChartChange = (piechart) => {
        // get all percentages
        const percentages = piechart.getAllSliceSizePercentages();
        setSupporting(Math.round(percentages[0]));
        setDetractors(Math.round(percentages[1]));
        setAncillary(Math.round(percentages[2]));
        setCore(Math.round(percentages[3]));
    };

    const calculatePoint = (radius, radian) => {
        let x = radius * Math.cos(radian);
        let y = radius * Math.sin(radian);
        return {
            x: x,
            y: y
        };
    };

    const drawBox = (context, x, y, w, h, r) => {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        context.save();
        context.beginPath();
        context.moveTo(x + r, y);
        context.arcTo(x + w, y, x + w, y + h, r);
        context.arcTo(x + w, y + h, x, y + h, r);
        context.arcTo(x, y + h, x,   y, r);
        context.arcTo(x, y,x + w, y, r);
        context.closePath();
        context.fillStyle = '#FFFFFF';
        context.strokeStyle = '#CECECE';
        context.fill();
        context.stroke();
        context.restore();
    };
    
    const drawArc = (context, centerX, centerY, radius, startAngle, endAngle, fill) => {
        context.save();
        context.beginPath();
        context.moveTo(centerX, centerY);
        context.arc(centerX, centerY, radius, startAngle, endAngle, false);
        context.closePath();
        context.fillStyle = fill;
        context.fill();
        context.strokeStyle = '#FFFFFF';
        context.stroke();
        context.restore();
    };
    
    const drawLabel = (context, centerX, centerY, x, y, size, percentage) => {
        context.save();
        context.moveTo(centerX, centerY);
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.font = size + 'pt Open Sans';
        context.fillText(percentage.toFixed(0).toString() + '%', x, y);
        context.restore();
    };

    const drawSegmentOutlineOnly = (context, piechart, centerX, centerY, radius, startingAngle, arcSize, format, collapsed) => {

        if (collapsed) { return; }

        const fontSize = Math.floor(context.canvas.height / 40);
        const percentage = 100 * arcSize / (Math.PI * 2);
        const midAngle = startingAngle + (arcSize / 2);
        const endingAngle = startingAngle + arcSize;

        const point = calculatePoint(radius + 20 + fontSize, midAngle);
        let x = centerX + point.x;
        let y = centerY + point.y;

        // Draw segment                
        drawArc(context, centerX, centerY, radius, startingAngle, endingAngle, format.color);

        // Draw box around label
        let width = fontSize * 4;
        let height = fontSize * 3;
        let boxX = x - (width / 2);
        let boxY = y - (height / 2);
        let r = 10;

        drawBox(context, boxX, boxY, width, height, r);

        drawLabel(context, centerX, centerY, x, y, fontSize, percentage);
    };

    useEffect(() => {
        if (pie !== null) {
            pie.updateColours([
                theme.supportingColour,
                theme.detractorColour,
                theme.ancillaryColour,
                theme.coreColour
            ]);    
        } 
    }, [theme]);

    useEffect(() => {

        const proportions = [
            { proportion: supporting, format: { color: theme.supportingColour, label: translations('Supporting') }},
            { proportion: detractors, format: { color: theme.detractorColour, label: translations('Detractors') }},
            { proportion: ancillary, format: { color: theme.ancillaryColour, label: translations('Ancillary') }},
            { proportion: core, format: { color: theme.coreColour, label: translations('Core') }}
        ];

        const canvas = pieRef.current;

        pie = new window.DraggablePiechart({
            canvas: canvas,
            radius: 0.75,
            proportions: proportions,
            onchange: onPieChartChange,
            drawSegment: drawSegmentOutlineOnly,
        });

    }, []);

    return (
        <div className="mb-4 text-center">
            <canvas ref={pieRef} />
        </div>
    );
};

HoursCard.propTypes = {
    theme: PropTypes.object,
    core: PropTypes.number,
    setCore: PropTypes.func,
    detractors: PropTypes.number,
    setDetractors: PropTypes.func,
    supporting: PropTypes.number,
    setSupporting: PropTypes.func,
    ancillary: PropTypes.number,
    setAncillary: PropTypes.func
};

export {
    HoursCard
};