import React from 'react';
import PropTypes from 'prop-types';
import {GainedProductivityIcon} from 'components/icons/GainedProductivityIcon';
import {useTranslation} from 'react-i18next';

const GainedProductivityCard = ({ isManager, theme, gainedProductivity }) => {

    const { t : translations } = useTranslation();
    
    const description = isManager
        ? translations('Gained_Productivity_Card_Public')
        : translations('Gained_Productivity_Card_Private');
    
    return (
        <div className="col">
            <div className="card h-100 mb-3" style={{ backgroundColor: '#F1F1F1' }}>
                <div className="card-body text-center">
                    <GainedProductivityIcon primaryColour={theme.primary} tertiaryColour={theme.secondary} />
                    <h4 className="card-title fw-bold my-3 mx-auto w-75">
                        {description}
                    </h4>
                    <p className="card-text fs-1" style={{ color: theme.primary }}>
                        {gainedProductivity} {translations('Gained_Productivity_Hours')}
                    </p>
                </div>
            </div>
        </div>
    );
};

GainedProductivityCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    gainedProductivity: PropTypes.number
};

export {
    GainedProductivityCard
};