import React, {useEffect, useState} from 'react';
import {Navigate, useLocation, Outlet} from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const RequiresAuth = () => {

    const { authorized, refreshToken } = useAuth();
    const location = useLocation();
    const [checked, setChecked] = useState(false);

    // Set up a heartbeat to keep the user alive whilst they have the app open 
    useEffect(() => {

        if (authorized) {
            setChecked(true);
            return;
        }
        
        const fetchData = async () => {
            await refreshToken();
            setChecked(true);
        };

        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }, 10);


    }, []);

    if (!checked) {
        return null;
    }
    
    return (
        authorized
            ? <Outlet />
            : <Navigate to="/account/sign-in" state={{ from: location }} replace />
    );
};

export {
    RequiresAuth
};
