import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ theme }) => {
    return (
        <footer className="footer" style={{ backgroundColor: theme.themeFooterBgColour }}>
            <p className="m-0"></p>
        </footer>
    );
};

Footer.propTypes = {
    theme: PropTypes.object
};

export {
    Footer
};
