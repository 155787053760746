const generateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
};

const formatErrors = (data) => {

    const list = [];

    if (data.errors) {
        // Model errors
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (let [key, value] of Object.entries(data.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            for (let [key2, value2] of Object.entries(value)) {
                list.push(`<p>${value2}</p>`);
            }
        }
    } else if (data.message) {
        // Bad request message
        list.push(`<p>${data.message}</p>`);
    } else if (data.detail) {
        // Bad request message
        list.push(`<p>${data.detail}</p>`);
    }

    return list.join('');
};

export default {
    generateGuid,
    formatErrors
};