import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import useNotifications from 'hooks/useNotifications';
import calculatorService from 'services/calculatorService';
import {CalculatorRow} from './CalculatorRow';

const CalculatorsTable = ({ calculators, copyLink, downloadData, togglePublish }) => {
    
    const calculatorRows = useMemo(() => {
        return calculators.map((c, index) => {
            return (
                <CalculatorRow
                    key={index}
                    id={c.id}
                    name={c.name}
                    created={c.created}
                    visits={c.visits}
                    submits={c.submissions}
                    copyClick={() => copyLink(c.id)}
                    downloadClick={() => downloadData(c.id)}
                    published={c.isPublished}
                    setPublished={() => togglePublish(c.id)}
                    manager={c.isManager}
                    isDefault={c.isDefault}
                />
            );
        });
    }, [calculators]);
        
    return (
        <>
            <table className={'table table-bordered bg-white d-none d-lg-table'}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>URL</th>
                        <th>Created On</th>
                        <th>&nbsp;</th>
                        <th>Published</th>
                        <th>Manager</th>
                        <th>Visits</th>
                        <th>Submits</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody className={'border-top-0'}>
                    {calculatorRows}
                </tbody>
            </table>
        </>
    );
};

CalculatorsTable.propTypes = {
    calculators: PropTypes.array.isRequired,
    copyLink: PropTypes.func.isRequired,
    downloadData: PropTypes.func.isRequired,
    togglePublish: PropTypes.func.isRequired,
};

export {
    CalculatorsTable
};