import {DateTime} from 'luxon';

export const FormattedDateTime = (dateTime) => {

    if (dateTime === null) {
        return null;
    }

    // Note: Do we want admin user to choose time zone ?
    const dt = DateTime.fromISO(dateTime).setZone(
        'Europe/London'
    );

    return dt.toLocaleString(DateTime.DATETIME_SHORT);
};
