import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Switch } from 'components/Switch';
import {FormattedDateTime} from '../../utilities/dateTimeUtilities';

// eslint-disable-next-line react/prop-types
const CalculatorRow = ({ id, name, created, visits, submits, copyClick, downloadClick, published, setPublished, manager, isDefault }) => {
    
    const navigate = useNavigate();

    return (
        <tr>
            <td>
                {name}
                {isDefault ? <i className="fas fa-star text-warning ms-2" title="Default Calculator" /> : null}
            </td>
            <td>
                <p className="d-flex mb-0">
                    <a href={'#'} onClick={(e) => { e.preventDefault(); copyClick(); }} className="flex-grow-0 align-self-center me-2">
                        Copy
                    </a>
                    <span className="flex-grow-1 align-self-center text-primary cursor-pointer">
                        <i className="fas fa-link" onClick={copyClick} style={{ fontSize: '1.25rem' }}></i>    
                    </span>
                </p>
            </td>
            <td>
                {FormattedDateTime(created)}
            </td>
            <td>
                <p className="d-flex mb-0">
                    <Link to={`/admin/calculators/${id}/edit`} className="flex-grow-0 align-self-center me-2">
                        Edit
                    </Link>
                    <span className="flex-grow-1 align-self-center text-primary cursor-pointer">
                        <i className="fas fa-pen" onClick={() => navigate('/admin/calculators/' + id + '/edit')} style={{ fontSize: '1.25rem' }}></i>    
                    </span>
                </p>
            </td>
            <td className="text-align-center text-center">
                {id === 1 ?
                    <Switch
                        checked={published}
                        setChecked={setPublished}
                        disabled={true}
                    />
                    :
                    <Switch
                        checked={published}
                        setChecked={setPublished}
                        disabled={false}
                    />
                }
            </td>
            <td className="text-align-center text-center">
                {manager ? <i className="fas fa-check text-primary" /> : null }
            </td>
            <td>
                {visits}
            </td>
            <td>
                {submits || 0}
            </td>
            <td>
                <p className="d-flex mb-0">
                    <span className="flex-grow-0 align-self-center text-primary text-decoration-underline cursor-pointer me-2" onClick={downloadClick}>
                        Download
                    </span>
                    <span className="flex-grow-1 align-self-center text-primary cursor-pointer">
                        <i className="fas fa-file-excel" onClick={downloadClick} style={{ fontSize: '1.25rem' }}></i>    
                    </span>
                </p>
            </td>
        </tr>
    );
};

export {
    CalculatorRow
};