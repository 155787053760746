import React from 'react';
import PropTypes from 'prop-types';
import {Header} from 'components/Header';

const OptionalHeader = ({ isWindowed, title, theme }) => {
    if (isWindowed) {
        return null;
    }
    return (
        <Header title={title} theme={theme} />
    );
};

OptionalHeader.propTypes = {
    isWindowed: PropTypes.bool,
    title: PropTypes.string,
    theme: PropTypes.object
};

export {
    OptionalHeader
};