import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({ title = 'Strategy to Revenue Productivity Calculator', theme }) => {
    
    const logoPath = theme.organisationId 
        ? `https://kompetently.com/images/logos/${theme.organisationId}/${theme.logo}`
        : '/str-logo-colour-s.png';
    
    const containerStyle = {
        backgroundColor: theme.organisationId ? theme.themeHeaderBgColour : '#FFFFFF'
    };

    const bannerStyle = {
        backgroundColor: theme.organisationId ? theme.coreColour : '#20243F',
        borderColor: theme.organisationId ? theme.primary : '#F0C851',
    };

    const bannerTextStyle = {
        color: theme.organisationId ? theme.coreTextColour : '#FFFFFF',
    };
    
    return (
        <>
            <div style={containerStyle}>
                <div className="container">
                    <header>
                        <div style={{ height: '120px', margin: '0 auto' }}>
                            <Link to="/">
                                <img src={logoPath} height="100%" alt="str-logo-colour-s" title="str-logo-colour-s" style={{ padding: '20px 0'}} />
                            </Link>
                        </div>
                    </header>
                </div>
            </div>
            <div className="banner" style={bannerStyle}>
                <h1 className="text-center py-4" style={bannerTextStyle}>
                    {title}
                </h1>
            </div>
        </>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    theme: PropTypes.object
};

export {
    Header
};
