import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DetractorsModal} from 'components/modals/DetractorsModal';
import {useTranslation} from 'react-i18next';

const DetractorsLegend = ({ theme }) => {

    const { t : translations } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };
    
    return (
        <>
            <div className="col">
                <p className="m-0 d-none d-sm-block text-lg-center cursor-pointer" onClick={toggleModal}>
                    <i className="fas fa-3x fa-info-circle align-middle" style={{ color: theme.detractorColour }}></i>
                    <span className="ps-3 fw-bold text-uppercase align-middle">
                        {translations('Detractors')}
                    </span>
                </p>
                <p className="m-0 d-sm-none text-center cursor-pointer" onClick={toggleModal}>
                    <i className="fas fa-3x fa-info-circle align-middle" style={{ color: theme.detractorColour }}></i>
                </p>
                <p className="m-0 pt-2 d-sm-none text-center cursor-pointer" onClick={toggleModal}>
                    <span className="fw-bold text-uppercase align-middle">
                        {translations('Detractors')}
                    </span>
                </p>
            </div>
            <DetractorsModal theme={theme} show={showModal} onHide={() => setShowModal(false)} />
        </>
    );
};

DetractorsLegend.propTypes = {
    theme: PropTypes.object
};

export {
    DetractorsLegend
};