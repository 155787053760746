import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import {HOURS_MIN, HOURS_MAX, HOURS_STEP, HOURS_LABELS} from 'settings';
import {handleRender} from 'components/HandleRender';
import {useTranslation} from 'react-i18next';

const HoursInWeekCard = ({ isManager, theme, hoursInWeek, setHoursInWeek }) => {

    const { t : translations } = useTranslation();
    
    const description = isManager
        ? translations('Hours_In_Week_Card_Public')
        : translations('Hours_In_Week_Card_Private');
    
    return (
        <div className="mb-5 p-3">
            <h4 className="text-center fw-bold my-4">
                {description}
            </h4>
            <div>
                <Slider 
                    min={HOURS_MIN} 
                    max={HOURS_MAX}
                    step={HOURS_STEP}
                    marks={HOURS_LABELS}
                    value={hoursInWeek}
                    handleRender={handleRender}
                    trackStyle={[{ backgroundColor: theme.primary }]}
                    handleStyle={[{ backgroundColor: theme.secondary }]}
                    onChange={(value) => setHoursInWeek(value)}
                />
            </div>
        </div>
    );
};

HoursInWeekCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    hoursInWeek: PropTypes.number,
    setHoursInWeek: PropTypes.func
};

export {
    HoursInWeekCard
};