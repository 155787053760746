import {Form} from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const ErrorFeedback = ({ errors }) => {
    if (!errors.length) {
        return null;
    }
    return (
        <Form.Control.Feedback type="invalid">
            {
                errors.map((e, index) => <p key={index}>{e}</p>)
            }
        </Form.Control.Feedback>
    );
};

ErrorFeedback.propTypes = {
    errors: PropTypes.array
};

export {
    ErrorFeedback
};