import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const SalesPeopleIcon = ({ primaryColour, tertiaryColour }) => {
    return (
        <svg width={CARD_ICON_WIDTH} height={CARD_ICON_HEIGHT} viewBox="0 0 200 224.571">
            <defs>
                <clipPath id="clip-path">
                    <rect width="200" height="224.571" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="200" height="217.303" fill="none"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
                <g>
                    <g clipPath="url(#clip-path)">
                        <g transform="translate(0 7.268)" opacity="0.75" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                            <g clipPath="url(#clip-path-3)">
                                <path d="M200,180.94V116.364a44.076,44.076,0,0,0-22.038-38.17L122.038,45.9a44.076,44.076,0,0,0-44.075,0L22.038,78.193A44.076,44.076,0,0,0,0,116.364V180.94a44.076,44.076,0,0,0,22.038,38.17L77.962,251.4a44.076,44.076,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,180.94" transform="translate(0 -40)" fill="#e8e9e8"/>
                            </g>
                        </g>
                        <path d="M200,140.94V76.364a44.076,44.076,0,0,0-22.038-38.17L122.038,5.9a44.076,44.076,0,0,0-44.075,0L22.038,38.193A44.076,44.076,0,0,0,0,76.364V140.94a44.076,44.076,0,0,0,22.038,38.17L77.962,211.4a44.076,44.076,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,140.94" transform="translate(0 0)" fill="#fff"/>
                        <path d="M707.1,507.894a10.839,10.839,0,1,1-10.839-10.839A10.839,10.839,0,0,1,707.1,507.894" transform="translate(-560.882 -406.741)" fill={primaryColour}/>
                        <path d="M530.38,465.129a12.43,12.43,0,1,1-12.43-12.43,12.43,12.43,0,0,1,12.43,12.43" transform="translate(-413.668 -370.445)" fill={tertiaryColour}/>
                        <path d="M313.516,499.357a10.838,10.838,0,1,0,3.457,12.383,72.915,72.915,0,0,1-3.457-12.383" transform="translate(-242.216 -406.741)" fill={primaryColour}/>
                        <path d="M455.814,481.891h-.022c-.27-1.58-3.158-16.832-14.394-19.046-6.279-1.236-6.009-.63-12.449-2.475a30.647,30.647,0,0,1-6.076-2.054c-12.352-4.937-10.445-18.1-12.518-25.14l-.021-.071c.008.022.013.022-.012-.1-1.193-5.743-10.723-6.986-7.992,7.47a68.337,68.337,0,0,0,6.655,21.336,24.579,24.579,0,0,1,1.809,3.826c1.566,5.326,1.46,11.487,1.024,16.063l-.069,7.942s44.65,1.692,44.65,0c0-2.581-.586-7.751-.586-7.751" transform="translate(-328.828 -351.035)" fill={tertiaryColour}/>
                        <path d="M696.6,628.833a19.191,19.191,0,0,0-10.763,3.282,31.041,31.041,0,0,1,5.556,19.332h24.489v-3.333A19.281,19.281,0,0,0,696.6,628.833" transform="translate(-561.224 -514.576)" fill={primaryColour}/>
                        <path d="M284.758,637.266a19.282,19.282,0,0,0-35.223,10.848v3.333h34.944c-.223-4.423.279-14.18.279-14.18" transform="translate(-204.195 -514.576)" fill={primaryColour}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

SalesPeopleIcon.propTypes = {
    primaryColour: PropTypes.string,
    tertiaryColour: PropTypes.string
};

export {
    SalesPeopleIcon
};