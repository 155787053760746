export const httpHeaders = () => {

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return new Headers({
            'Authorization': `Bearer ${accessToken}`
        });
    }

    return new Headers({});
};

export const httpHeadersJson = () => {

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        return new Headers({
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${accessToken}`
        });
    }

    return new Headers({
        'Content-type': 'application/json; charset=UTF-8',
    });
};
