import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const ProductivityPotentialIcon = ({ primaryColour, tertiaryColour }) => {
    return (
        <svg width={CARD_ICON_WIDTH} height={CARD_ICON_HEIGHT} viewBox="0 0 200 224.571">
            <defs>
                <clipPath id="clip-path">
                    <rect width="200" height="224.571" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="200" height="217.303" fill="none"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
                <g>
                    <g clipPath="url(#clip-path)">
                        <g transform="translate(0 7.268)" opacity="0.75" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                            <g clipPath="url(#clip-path-3)">
                                <path d="M200,180.94V116.364a44.076,44.076,0,0,0-22.038-38.17L122.038,45.9a44.075,44.075,0,0,0-44.075,0L22.038,78.193A44.076,44.076,0,0,0,0,116.364V180.94a44.076,44.076,0,0,0,22.038,38.17L77.962,251.4a44.075,44.075,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,180.94" transform="translate(0 -40)" fill="#e8e9e8"/>
                            </g>
                        </g>
                        <path d="M200,140.94V76.364a44.076,44.076,0,0,0-22.038-38.17L122.038,5.9a44.076,44.076,0,0,0-44.075,0L22.038,38.193A44.076,44.076,0,0,0,0,76.364V140.94a44.076,44.076,0,0,0,22.038,38.17L77.962,211.4a44.076,44.076,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,140.94" transform="translate(0 0)" fill="#fff"/>
                        <path d="M707.1,571.381a10.839,10.839,0,1,1-10.839-10.839A10.839,10.839,0,0,1,707.1,571.381" transform="translate(-560.882 -458.693)" fill={primaryColour}/>
                        <path d="M530.38,528.616a12.43,12.43,0,1,1-12.43-12.43,12.43,12.43,0,0,1,12.43,12.43" transform="translate(-413.668 -422.396)" fill={tertiaryColour}/>
                        <path d="M313.516,562.844a10.838,10.838,0,1,0,3.457,12.383,72.91,72.91,0,0,1-3.457-12.383" transform="translate(-242.216 -458.693)" fill={primaryColour}/>
                        <path d="M455.814,545.377h-.022c-.27-1.58-3.158-16.832-14.394-19.046-6.279-1.236-6.009-.63-12.449-2.475a30.641,30.641,0,0,1-6.076-2.054c-12.352-4.937-10.445-18.1-12.518-25.141l-.021-.071c.008.022.013.022-.012-.1-1.193-5.743-10.723-6.986-7.992,7.47a68.336,68.336,0,0,0,6.655,21.336,24.586,24.586,0,0,1,1.809,3.826c1.566,5.326,1.46,11.487,1.023,16.063l-.069,7.942s44.65,1.692,44.65,0c0-2.581-.586-7.751-.586-7.751" transform="translate(-328.828 -402.986)" fill={tertiaryColour}/>
                        <path d="M696.6,692.32a19.191,19.191,0,0,0-10.763,3.282,31.041,31.041,0,0,1,5.556,19.332h24.489V711.6A19.281,19.281,0,0,0,696.6,692.32" transform="translate(-561.224 -566.527)" fill={primaryColour}/>
                        <path d="M284.758,700.754A19.282,19.282,0,0,0,249.535,711.6v3.332h34.944c-.223-4.423.279-14.18.279-14.18" transform="translate(-204.195 -566.527)" fill={primaryColour}/>
                        <path d="M547.574,344.068l-2.509-.815q.064-.64.065-1.3t-.065-1.3l2.509-.815a.88.88,0,0,0,.563-1.106L546.99,335.2a.88.88,0,0,0-1.106-.563l-2.508.815a12.96,12.96,0,0,0-1.527-2.1l1.552-2.135a.88.88,0,0,0-.194-1.226l-3.005-2.183a.88.88,0,0,0-1.225.194l-1.55,2.133a12.8,12.8,0,0,0-2.469-.8V326.7a.88.88,0,0,0-.878-.877h-3.715a.88.88,0,0,0-.878.877v2.637a12.8,12.8,0,0,0-2.469.8l-1.55-2.133a.88.88,0,0,0-1.226-.194L521.238,330a.88.88,0,0,0-.194,1.226l1.551,2.135a12.942,12.942,0,0,0-1.527,2.1l-2.508-.815a.88.88,0,0,0-1.106.563l-1.148,3.533a.88.88,0,0,0,.563,1.106l2.509.815q-.064.64-.065,1.3t.065,1.3l-2.509.815a.88.88,0,0,0-.563,1.106l1.148,3.533a.88.88,0,0,0,1.106.563l2.508-.815a12.942,12.942,0,0,0,1.527,2.1l-1.551,2.135a.88.88,0,0,0,.194,1.226l3.005,2.183a.88.88,0,0,0,1.226-.194l1.55-2.133a12.8,12.8,0,0,0,2.469.8v2.637a.88.88,0,0,0,.878.877h3.715a.88.88,0,0,0,.878-.877v-2.637a12.8,12.8,0,0,0,2.469-.8l1.55,2.133a.88.88,0,0,0,1.225.194l3.005-2.183a.88.88,0,0,0,.194-1.226l-1.552-2.135a12.961,12.961,0,0,0,1.527-2.1l2.508.815a.88.88,0,0,0,1.106-.563l1.148-3.533a.88.88,0,0,0-.563-1.106m-15.352,3.846a5.958,5.958,0,1,1,5.959-5.959,5.959,5.959,0,0,1-5.959,5.959" transform="translate(-422.46 -266.623)" fill={primaryColour}/>
                        <path d="M560.365,247.022a2.262,2.262,0,0,1,2.313.879l.92,1.266q.294-.1.592-.192V247.41a2.266,2.266,0,0,1,2.263-2.263h3.715a2.257,2.257,0,0,1,1.709.784v-17H560.365Z" transform="translate(-458.548 -187.332)" fill={tertiaryColour}/>
                        <path d="M492.01,289.4c0-.1,0-.207,0-.311s0-.207,0-.311l-1.49-.484a2.266,2.266,0,0,1-1.453-2.852l1.148-3.533a2.264,2.264,0,0,1,2.852-1.453l1.49.484q.177-.255.365-.5L494,279.175a2.266,2.266,0,0,1,.5-3.161l1.6-1.16V261.225H484.586v29.343H489.5a2.246,2.246,0,0,1,1.015-.68Z" transform="translate(-396.538 -213.761)" fill={tertiaryColour}/>
                        <rect width="11.512" height="23.023" transform="translate(74.279 53.784)" fill={tertiaryColour}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

ProductivityPotentialIcon.propTypes = {
    primaryColour: PropTypes.string,
    tertiaryColour: PropTypes.string
};

export {
    ProductivityPotentialIcon
};