import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Link, useSearchParams} from 'react-router-dom';
import useValidatedField from 'hooks/useValidatedField';
import validationUtilities from 'utilities/validationUtilities';
import {ErrorFeedback} from 'components/ErrorFeedback';
import useNotifications from '../../hooks/useNotifications';
import UserService from '../../services/userService';

const ChangePassword = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    const { showMessage } = useNotifications();
    const [password, setPassword, passwordErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [confirmPassword, setConfirmPassword, confirmPasswordErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [success, setSuccess] = useState(false);
    
    const changePassword = async () => {
        const response = await UserService.changePassword({
            email: email,
            code: code,
            password: password
        });
        await response;
    };
    
    const submit = async (e) => {
        try {
            e.preventDefault();
            if (password !== confirmPassword) {
                showMessage('Validation Failed', 'Passwords do not match.');
                return;
            }
            await changePassword();
            setSuccess(true);
        } catch (ex) {
            if (ex && ex.status === 401) {
                showMessage('Authentication Failed', 'Please check your details and try again.');
            }
        }
    };
    
    useEffect(() => {
        let disable = false;
        if (password.length === 0 || passwordErrors.length) {
            disable = true;
        }
        if (confirmPassword.length === 0 || confirmPasswordErrors.length) {
            disable = true;
        }
        setDisableSubmit(disable);
    }, [passwordErrors, confirmPasswordErrors]);

    const styles = {
        backgroundImage: 'url(/img/login.png)',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: '600px'
    };
    
    return (
        <>
            <div className="container" style={styles}>
                <div className="container" style={{ maxWidth: '600px'}}>
                    <div className="str-card my-4 p-4">

                        <h2 className="h3 mb-4">
                            Change Password
                        </h2>
                        
                        <ChangeForm 
                            success={success}
                            password={password}
                            setPassword={setPassword}
                            passwordErrors={passwordErrors}
                            confirmPassword={confirmPassword}
                            setConfirmPassword={setConfirmPassword}
                            confirmPasswordErrors={confirmPasswordErrors}
                            submit={submit}
                            disableSubmit={disableSubmit}
                        />
                        
                        <SuccessMessage success={success} />
                            
                    </div>
                </div>
            </div>
        </>
    );
};

/* eslint-disable react/prop-types */
const ChangeForm = ({ success, password, setPassword, passwordErrors, confirmPassword, setConfirmPassword, confirmPasswordErrors, submit, disableSubmit }) => {
    
    if (success) {
        return null;
    }
    
    return (
        <form onSubmit={submit}>

            <div className="mb-4">
                <Form.Label>
                    Password
                </Form.Label>
                <Form.Control
                    autoFocus={true}
                    type="password"
                    name="password"
                    value={password}
                    isInvalid={passwordErrors && passwordErrors.length > 0}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <ErrorFeedback errors={passwordErrors || []} />
            </div>

            <div className="mb-4">
                <Form.Label>
                    Confirm Password
                </Form.Label>
                <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    isInvalid={confirmPasswordErrors && confirmPasswordErrors.length > 0}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <ErrorFeedback errors={confirmPasswordErrors || []} />
            </div>

            <p>
                <i className="fas fa-info text-primary"/> We&apos;ll ask you to sign in after you save any changes for security reasons.
            </p>

            <div className="mb-4">
                <p className="text-center m-0">
                    <Link to="/account/sign-in">
                        Back
                    </Link>
                </p>
            </div>

            <div className="text-center">
                <Button type="submit" className="text-uppercase" variant={'warning'} size={'lg'} onClick={submit} disabled={disableSubmit}>
                    Change Password
                </Button>
            </div>

        </form>
    );
};

/* eslint-disable react/prop-types */
const SuccessMessage = ({ success }) => {
    if (!success) {
        return null;    
    }    
    return (
        <>
            <div className="mb-4">
                <p>
                    Your password has been successfully changed
                </p>
                <p className="m-0">
                    Please <Link to="/account/sign-in">sign in</Link> to continue.
                </p>
            </div>
            <div className="mb-4">
                <p className="text-center m-0">
                    <Link to="/account/sign-in">
                        Back
                    </Link>
                </p>
            </div>
        </>
    );
};

export {
    ChangePassword
};