import {httpClient, handleBlobResponse, handleJsonResponse} from './httpClient';

const API_URL = 'api/v1/calculators';

const addCalculator = (data) => {
    return httpClient.postJson(`${API_URL}`, data)
        .then(handleJsonResponse);
};

const addVisit = (id) => {
    return httpClient.postJson(`${API_URL}/${id}/visit`)
        .then(handleJsonResponse);
};

const getCalculator = (id) => {
    return httpClient.get(`${API_URL}/${id}`)
        .then(handleJsonResponse);
};

const getCalculatorByHash = (id) => {
    return httpClient.get(`${API_URL}/${id}/settings`)
        .then(handleJsonResponse);
};

const getCalculators = () => {
    return httpClient.get(`${API_URL}`)
        .then(handleJsonResponse);
};

const getLanguages = () => {
    return httpClient.get(`${API_URL}/languages`)
        .then(handleJsonResponse);
};

const getThemes = () => {
    return httpClient.get(`${API_URL}/themes`)
        .then(handleJsonResponse);
};

const getDefaultCalculator = () => {
    return httpClient.get(`${API_URL}/default-calculator`)
        .then(handleJsonResponse);
};

const downloadData = (id) => {
    return httpClient.post(`${API_URL}/${id}/download-data`)
        .then(handleBlobResponse);
};

const togglePublished = (id) => {
    return httpClient.postJson(`${API_URL}/${id}/toggle-published`)
        .then(handleJsonResponse);
};

const updateCalculator = (id, data) => {
    return httpClient.putJson(`${API_URL}/${id}`, data)
        .then(handleJsonResponse);
};

const deleteResults = (id) => {
    return httpClient.putJson(`${API_URL}/${id}/delete-results`)
        .then(handleJsonResponse);
};

export default {
    addCalculator,
    addVisit,
    getCalculator,
    getCalculatorByHash,
    getCalculators,
    getLanguages,
    getThemes,
    getDefaultCalculator,
    downloadData,
    togglePublished,
    updateCalculator,
    deleteResults
};