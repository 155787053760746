import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

const CalculatorsList = ({ calculators }) => {

    const navigate = useNavigate();

    const calculatorItems = useMemo(() => {
        return calculators.map((c, index) => {
            return (
                <li key={index} className="list-group-item" onClick={() => navigate('/admin/calculators/' + c.id + '/edit')}>
                    {c.name}
                    {c.isDefault ? <i className="fas fa-star text-warning ms-2" title="Default Calculator" /> : null}
                </li>
            );
        });
    }, [calculators]);
    
    return (
        <>
            <ul className="list-group d-flex d-lg-none">
                {calculatorItems}
            </ul>
        </>
    );
};

CalculatorsList.propTypes = {
    calculators: PropTypes.array.isRequired
};

export {
    CalculatorsList
};