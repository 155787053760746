import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {SalesLeadershipIcon} from '../../../components/icons/SalesLeadershipIcon';

const SalesLeadershipCard = ({ primaryColour, secondaryColour, jobRole, setJobRole }) => {

    const { t : translations } = useTranslation();
    
    const role = 'Sales Leadership';
    
    const onClick = async (e) => {
        e.preventDefault();
        setJobRole(role);
    };
    
    return (
        <div className="col">
            <div className="JobRoleCard card h-100 cursor-pointer" onClick={onClick} style={{ backgroundColor: '#F1F1F1' }}>
                <div className="d-flex">
                    <div className="flex-grow-0 align-self-center p-3" style={{ width: 100 }}>
                        <p className="m-0 text-center ">
                            <SalesLeadershipIcon 
                                primaryColour={primaryColour} 
                                secondaryColour={secondaryColour}
                                size={75}
                            />
                        </p>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                        <p className="m-0">
                            {translations('Job_Role_Sales_Leadership')}
                        </p>
                    </div>
                    <div className="flex-grow-0 align-self-center text-center" style={{ width: 100 }}>
                        <input
                            type="radio"
                            name="JobRole"
                            style={{ pointerEvents: 'none' }} 
                            className="form-check-input JobRoleRadio"
                            value={role}
                            checked={jobRole === role}
                            readOnly={true}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

SalesLeadershipCard.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    jobRole: PropTypes.string,
    setJobRole: PropTypes.func
};

export {
    SalesLeadershipCard
};