import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const SalesLeadershipIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 97.61 97">
            <g>
                <path fill={secondaryColour} d="M87.44,53.72c0,4.54-3.68,8.23-8.23,8.23s-8.23-3.68-8.22-8.23c0-4.54,3.68-8.23,8.23-8.23,4.54,0,8.23,3.68,8.23,8.23Z" />
                <path fill={secondaryColour} d="M79.64,63.67c-.14,0-.29,0-.43,0s-.29,0-.43,0c-8.08,0-14.63,6.55-14.63,14.63v2.53h30.13v-2.53c0-8.08-6.55-14.63-14.64-14.63Z" />
                <path fill={primaryColour} d="M66.72,66.08l-17.4-30.7c-.92-1.63-2.99-2.2-4.62-1.28-.56,.32-1.02,.79-1.32,1.35l-10.25,19.21,5.48,9.59c1.02,1.79,.7,4.04-.77,5.47-.63-.09-.79-.12-1.42-.21l-10.79-18.88c-.93-1.62-3-2.19-4.62-1.26-.56,.32-1.02,.8-1.32,1.37l-15.11,28.85c-.22,.41-.06,.93,.36,1.14,.12,.06,.26,.1,.39,.1H61.59c-.13-2.58,.11-5.16,.72-7.67,.91-2.67,2.41-5.09,4.41-7.08Z" />
                <path fill={primaryColour} d="M45.95,17.53v10.81c0,.5,.4,.9,.9,.9h16.97c.5,0,.9-.4,.9-.9,0-.22-.08-.42-.22-.59l-3.63-4.23c-.29-.34-.29-.84,0-1.17l3.63-4.23c.32-.38,.28-.95-.1-1.27-.16-.14-.37-.22-.59-.22h-16.97c-.5,0-.9,.4-.9,.9h0Z" />
            </g>
        </svg>
    );
};

SalesLeadershipIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    SalesLeadershipIcon
};