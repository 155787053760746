import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ARTIFICIAL_DELAY, CONTROL_STATE} from 'settings';
import useNotifications from 'hooks/useNotifications';
import i18next from 'i18next';
import calculatorService from 'services/calculatorService';
import calculatorResultService from 'services/calculatorResultService';
import {
    calculateGainedProductivity,
    calculateProductivityPotential,
    calculateSales
} from 'utilities/calculator';
import {HoursInWeekCard} from './components/HoursInWeekCard';
import {SalesPeopleCard} from './components/SalesPeopleCard';
import {VacationCard} from './components/VacationCard';
import {TargetCard} from './components/TargetCard';
import {TargetAttainmentCard} from './components/TargetAttainmentCard';
import {SalesCard} from './components/SalesCard';
import {GainedProductivityCard} from './components/GainedProductivityCard';
import {ProductivityPotentialCard} from './components/ProductivityPotentialCard';
import {CoreLegend} from './components/CoreLegend';
import {DetractorsLegend} from './components/DetractorsLegend';
import {SupportingLegend} from './components/SupportingLegend';
import {AncillaryLegend} from './components/AncillaryLegend';
import {HoursCard} from './components/HoursCard';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {OptionalHeader} from './components/OptionalHeader';
import {OptionalSubmit} from './components/OptionalSubmit';
import {OptionalFooter} from './components/OptionalFooter';
import {JobRoles} from './components/JobRoles';

const RootIndex = () => {

    const { hash } = useParams();
    const navigate = useNavigate();
    const { showMessage } = useNotifications();
    const [pageState, setPageState] = useState(CONTROL_STATE.LOADING);
    const sessionId = sessionStorage.getItem('SessionId');

    // Calculator Settings
    const [id, setId] = useState();
    const [pageTitle, setPageTitle] = useState('');
    const [bannerTitle, setBannerTitle] = useState('');
    const [strapLine, setStrapLine] = useState('');
    const [ancillarySaving, setAncillarySaving] = useState(25);
    const [supportingSaving, setSupportingSaving] = useState(50);
    const [detractorsSaving, setDetractorsSaving] = useState(100);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [isPublic, setIsPublic] = useState(true);
    const [isManager, setIsManager] = useState(false);
    const [isWindowed, setIsWindowed] = useState(false);
    const [hasJobRole, setHasJobRole] = useState(false);
    const [languageCode, setLanguageCode] = useState('en-GB');
    
    const [theme, setTheme] = useState({
        organisationId: '',
        logo: '',
        primary: '#2B62FF',
        primaryText: '#FFFFFF',
        secondary: '#F0C851',
        secondaryText: '#FFFFFF',
        themeHeaderBgColour: '#20243F',
        themeHeaderColour: '#FFFFFF',
        themeBgColour: '#FFFFFF',
        themeColour: '#333333',
        themeFooterBgColour: '#20243F',
        themeFooterColour: '#FFFFFF',
        coreColour: '#559B84',
        coreTextColour: '#FFFFFF',
        detractorColour: '#949494',
        detractorTextColour: '#FFFFFF',
        supportingColour: '#2B62FF',
        supportingTextColour: '#FFFFFF',
        ancillaryColour: '#F0C851',
        ancillaryTextColour: '#333333'
    });
    
    // Calculation fields
    const [hoursInWeek, setHoursInWeek] = useState(35);
    const [core, setCore] = useState(25);
    const [detractors, setDetractors] = useState(25);
    const [supporting, setSupporting] = useState(25);
    const [ancillary, setAncillary] = useState(25);
    const [numberSalesPeople, setNumberSalesPeople] = useState(50);
    const [vacation, setVacation] = useState(28);
    const [target, setTarget] = useState(800000);
    const [targetAttainment, setTargetAttainment] = useState(15);
    const [sales, setSales] = useState(0);
    const [gainedProductivity, setGainedProductivity] = useState(0);
    const [productivityPotential, setProductivityPotential] = useState(0);
    const [jobRole, setJobRole] = useState('Account Management');
    
    const submitAssessmentClick = async (e) => {
        try {
            e.preventDefault();
            await submitAssessment();
        } catch (error) {
            showMessage(error.title, `<p>The server returned the following message:</p><p>${error.status} - ${error.message}</p>`);
        }
    };
    
    const autoSubmitAssessment = async () => {
        
        if (id !== 1) {
            return;
        }

        // Submit assessment, for private calculators
        const response = await calculatorResultService.submitResult(id, {
            calculatorId: id,
            sessionId,
            hoursInWeek,
            core,
            detractors,
            supporting,
            ancillary,
            numberSalesPeople,
            vacation,
            target,
            targetAttainment,
            sales,
            gainedProductivity,
            productivityPotential,
            jobRole: hasJobRole ? jobRole : ''
        });
        await response;
    };
    
    const submitAssessment = async () => {        
        // Submit assessment, for private calculators
        const response = await calculatorResultService.submitResult(id, {
            calculatorId: id,
            hoursInWeek,
            core,
            detractors,
            supporting,
            ancillary,
            numberSalesPeople,
            vacation,
            target,
            targetAttainment,
            sales,
            gainedProductivity,
            productivityPotential,
            jobRole: hasJobRole ? jobRole : ''
        });
        const resultId = await response;
        navigate('/messages/' + resultId + '/ThankYou');
    };
    
    const loadCalculator = async () => {

        const response = hash 
            ? await calculatorService.getCalculatorByHash(hash)
            : await calculatorService.getDefaultCalculator();
        
        const calculatorSettings = await response;

        if (calculatorSettings.isPublished === false) {
            navigate('/messages/Unpublished');
            return;
        }

        setId(calculatorSettings.id);
        setPageTitle(calculatorSettings.pageTitle);
        setBannerTitle(calculatorSettings.bannerTitle);
        setStrapLine(calculatorSettings.strapLine);
        setDetractorsSaving(calculatorSettings.detractors);
        setSupportingSaving(calculatorSettings.supporting);
        setAncillarySaving(calculatorSettings.ancillary);
        setCurrencySymbol(calculatorSettings.currencySymbol);
        setLanguageCode(calculatorSettings.languageCode);
        setNumberSalesPeople(calculatorSettings.isManager ? 1 : 50);
        setIsManager(calculatorSettings.isManager);
        setIsPublic(calculatorSettings.isPublic);
        setIsWindowed(calculatorSettings.isWindowed);
        setHasJobRole(calculatorSettings.hasJobRole);

        if (calculatorSettings.organisationId) {
            setTheme({
                organisationId: calculatorSettings.organisationId,
                logo: calculatorSettings.logo,
                primary: calculatorSettings.primary,
                primaryText: calculatorSettings.primaryText,
                secondary: calculatorSettings.secondary,
                secondaryText: calculatorSettings.secondaryText,
                themeHeaderBgColour: calculatorSettings.themeHeaderBgColour,
                themeHeaderColour: calculatorSettings.themeHeaderColour,
                themeBgColour: calculatorSettings.themeBgColour,
                themeColour: calculatorSettings.themeColour,
                themeFooterBgColour: calculatorSettings.themeFooterBgColour,
                themeFooterColour: calculatorSettings.themeFooterColour,
                coreColour: '#333333',
                coreTextColour: '#FFFFFF',
                detractorColour: '#606060',
                detractorTextColour: '#FFFFFF',
                supportingColour: '#9B9B9B',
                supportingTextColour: '#FFFFFF',
                ancillaryColour: '#BCBCBC',
                ancillaryTextColour: '#FFFFFF'
            });
        }

        if (calculatorSettings.isPublic) {
            const visitResponse = await calculatorService.addVisit(calculatorSettings.id);
            await visitResponse;    
        }
    };
    
    useEffect(() => {
        
        let gainedProductivityValue = calculateGainedProductivity(
            ancillary, ancillarySaving, detractors, detractorsSaving, supporting, supportingSaving, hoursInWeek, vacation
        );        
        setGainedProductivity(gainedProductivityValue);

        let salesValue = calculateSales(
            target, targetAttainment, hoursInWeek, vacation
        );
        setSales(salesValue);

        let productivityPotentialValue = calculateProductivityPotential(
            salesValue, gainedProductivityValue, numberSalesPeople
        );
        setProductivityPotential(productivityPotentialValue);

        autoSubmitAssessment().then();
        
    }, [
        ancillary, core, detractors, supporting, 
        ancillarySaving, detractorsSaving, supportingSaving, target, 
        targetAttainment, hoursInWeek, vacation, numberSalesPeople,
        jobRole
    ]);

    useEffect(() => {

        if (!languageCode) {
            return;
        }

        const option = languageCode.replace('-', '_');

        i18next.changeLanguage(option, (err, t) => {
            if (err) {
                return console.error('something went wrong loading', err);
            }
        }).then();

    }, [languageCode]);


    useEffect(() => {

        const fetchData = async () => {
            try {
                setPageState(CONTROL_STATE.LOADING);
                await loadCalculator();
            } catch (error) {
                showMessage(error.title, `<p>The server returned the following message:</p><p>${error.status} - ${error.message}</p>`);
                console.error(error);
            } finally {
                setPageState(CONTROL_STATE.READY);
            }
        };

        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }, ARTIFICIAL_DELAY);

    }, []);

    if (pageState === 'LOADING') {
        return (
            <LoadingSpinner />
        );
    }
    
    return (
        <div style={{ backgroundColor: theme.themeBgColour }}>
            
            <OptionalHeader isWindowed={isWindowed} title={bannerTitle} theme={theme} />
            
            <div className="container py-4">
                
                <div className="str-card position-relative p-4">
                    
                    <h1 className="fw-bold pe-5">
                        {pageTitle}
                    </h1>
                    
                    <div dangerouslySetInnerHTML={{ __html: strapLine }} />

                    <HoursCard
                        theme={theme}
                        core={core} 
                        setCore={setCore}
                        detractors={detractors} 
                        setDetractors={setDetractors}
                        supporting={supporting} 
                        setSupporting={setSupporting}
                        ancillary={ancillary} 
                        setAncillary={setAncillary} 
                    />

                    <div className="row row-cols-2 row-cols-lg-4 g-4 mb-4">
                        <CoreLegend core={core} theme={theme} />
                        <SupportingLegend supporting={supporting} theme={theme} />
                        <DetractorsLegend detractors={detractors} theme={theme} />
                        <AncillaryLegend ancillary={ancillary} theme={theme} />
                    </div>
                    
                    <JobRoles
                        hasJobRole={hasJobRole}
                        theme={theme}
                        jobRole={jobRole}
                        setJobRole={setJobRole}
                    />
                    
                    <div className={!isManager ? 'row row-cols-1 g-4 mb-4' : 'row row-cols-1 row-cols-lg-2 g-4 mb-4'}>
                        
                        <SalesPeopleCard 
                            isManager={isManager}
                            theme={theme} 
                            numberSalesPeople={numberSalesPeople} 
                            setNumberSalesPeople={setNumberSalesPeople} 
                        />
                        
                        <VacationCard
                            isManager={isManager}
                            theme={theme} 
                            vacation={vacation} 
                            setVacation={setVacation} 
                        />
                        
                    </div>

                    <HoursInWeekCard
                        isManager={isManager}
                        theme={theme} 
                        hoursInWeek={hoursInWeek} 
                        setHoursInWeek={setHoursInWeek} 
                    />
                    
                    <TargetCard 
                        isManager={isManager}
                        theme={theme} 
                        target={target} 
                        setTarget={setTarget} 
                        currencySymbol={currencySymbol} 
                    />
                    
                    <TargetAttainmentCard 
                        isManager={isManager}
                        theme={theme} 
                        targetAttainment={targetAttainment} 
                        setTargetAttainment={setTargetAttainment}
                    />

                    <div className="row row-cols-1 row-cols-lg-2 g-4 mb-4">
                        
                        <SalesCard 
                            isManager={isManager}
                            theme={theme}
                            sales={sales}
                            currencySymbol={currencySymbol} 
                        />
                        
                        <GainedProductivityCard 
                            isManager={isManager}
                            theme={theme} 
                            gainedProductivity={gainedProductivity} 
                        />
                        
                    </div>

                    <ProductivityPotentialCard 
                        isPublic={isPublic} 
                        currencySymbol={currencySymbol}
                        theme={theme}
                        productivityPotential={productivityPotential} 
                    />

                    <OptionalSubmit 
                        isPublic={isPublic} 
                        onClick={submitAssessmentClick} 
                        theme={theme} 
                    />
                    
                </div>
            </div>           
            
            <OptionalFooter isWindowed={isWindowed} theme={theme} />
            
        </div>
    );
};

export {
    RootIndex
};