import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const VacationIcon = ({ primaryColour, tertiaryColour }) => {
    return (
        <svg width={CARD_ICON_WIDTH} height={CARD_ICON_HEIGHT} viewBox="0 0 200 227.836">
            <defs>
                <clipPath id="clip-path">
                    <rect width="200" height="227.836" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="200" height="220.462" fill="none"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
                <g>
                    <g clipPath="url(#clip-path)">
                        <g transform="translate(0 7.374)" opacity="0.75" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                            <g clipPath="url(#clip-path-3)">
                                <path d="M200,182.988V117.474a44.839,44.839,0,0,0-22.038-38.725L122.038,45.991a43.529,43.529,0,0,0-44.075,0L22.038,78.748A44.839,44.839,0,0,0,0,117.474v65.515a44.839,44.839,0,0,0,22.038,38.725l55.925,32.757a43.529,43.529,0,0,0,44.075,0l55.925-32.757A44.839,44.839,0,0,0,200,182.988" transform="translate(0 -40)" fill="#e8e9e8"/>
                            </g>
                        </g>
                        <path d="M200,142.988V77.474a44.839,44.839,0,0,0-22.038-38.725L122.038,5.991a43.529,43.529,0,0,0-44.075,0L22.038,38.748A44.839,44.839,0,0,0,0,77.474v65.515a44.839,44.839,0,0,0,22.038,38.725l55.925,32.757a43.529,43.529,0,0,0,44.075,0l55.925-32.757A44.839,44.839,0,0,0,200,142.988" fill="#fff"/>
                        <path d="M628.421,513.19s-1.773-8.795-15.367-5.2c0,0,10.243,1.8,10.441,5.6,0,0-10.835-3.6-15.562,7,0,0,4.727-5,12.016-1,0,0-5.91-1-7.289,10.594a10.151,10.151,0,0,1,7.7-5.6c-5.67,6.561-8.63,16.444-10.246,24.128,0,0,1.945.089,4.007.368s4.427.855,4.427.855a51.077,51.077,0,0,1,6.828-24.526c1.4,1.343,5.283,6.174,5.6,18.762a23.01,23.01,0,0,0-1.182-24.784s7.091,1.6,9.258,11.193c0,0,2.365-20.786-10.636-17.389" transform="translate(-497.473 -413.645)" fill={tertiaryColour}/>
                        <path d="M371.421,416.264q-.472,0-.941.009c-1.848-11.76-6.623-33.577-17.784-46.558,8.628.993,12.048,8.746,12.048,8.746-2.155-18.113-11.391-16.551-11.391-16.551,11.391-6.245,18.778,1.562,18.778,1.562-7.387-16.552-24.317-10.93-24.317-10.93.306-5.934,16.314-8.745,16.314-8.745-21.239-5.622-24.011,8.119-24.011,8.119-20.315-5.309-19.535,28.061-19.535,28.061,3.386-14.99,16.12-18.007,16.12-18.007-13.85,19.986-.585,38.351-.585,38.351.5-19.666,6.565-27.217,8.757-29.315,4.17,6.977,12.392,24.1,10.391,48.111-9.279,3.555-15.5,10.079-15.5,17.539h63.309c0-11.262-14.174-20.392-31.656-20.392" transform="translate(-262.329 -279.32)" fill={primaryColour}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

VacationIcon.propTypes = {
    primaryColour: PropTypes.string,
    tertiaryColour: PropTypes.string
};

export {
    VacationIcon
};