export const formatCurrency = () => {
    // Calculoid :  ( ANCILLARY *0.75)+ ( SUPPORTING *0.5)+ DETRACTORS 
    // Excel: = Working Weeks in year * ( SUPPORTING *0.25) + DETRACTORS + (ANCILLARY *0.5)
    return 0;
};

export const getWorkingDays = (vacationDays) => {
    // Take into account weekends
    //return 365 - vacationDays;
    return 365 - 104 - vacationDays;
};

export const getWorkingWeeks = (workingDays) => {
    return workingDays / 7;
};

export const round = (value) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const calculateGainedProductivity = (ancillary, ancillarySaving, detractors, detractorsSaving, supporting, supportingSaving, hoursInWeek, vacation) => {

    if (hoursInWeek === 0) {
        return 0;
    }

    // Number of hours realized from minimisation of time back from Detractors and improvements in Efficiency per year    
    // Gained PHR per week x working weeks = Hours gained in a year
    let workingDays = getWorkingDays(vacation);
    let hoursPerDay = hoursInWeek / 5;
    let hoursInYear = hoursPerDay * workingDays;

    let ancillaryHoursInYear = hoursInYear * (ancillary / 100);
    let detractorHoursInYear = hoursInYear * (detractors / 100);
    let supportingHoursInYear = hoursInYear * (supporting / 100);

    let ancillarySavings = (ancillarySaving / 100) * ancillaryHoursInYear;
    let detractorSavings = (detractorsSaving / 100) * detractorHoursInYear;
    let supportingSavings = (supportingSaving / 100) * supportingHoursInYear;

    return Math.ceil(ancillarySavings + detractorSavings + supportingSavings);
};

export const calculateGainedProductivityHours = (ancillary, ancillarySaving, detractors, detractorsSaving, supporting, supportingSaving, hoursInWeek, vacation) => {

    if (hoursInWeek === 0) {
        return 0;
    }
    
    // Number of hours realized from minimisation of time back from Detractors and improvements in Efficiency per year    
    // Gained PHR per week x working weeks = Hours gained in a year
    let workingDays = getWorkingDays(vacation);
    let hoursPerDay = hoursInWeek / 5;
    let hoursInYear = hoursPerDay * workingDays;
    
    let ancillaryHoursInYear = hoursInYear * (ancillary / 100);
    let detractorHoursInYear = hoursInYear * (detractors / 100);
    let supportingHoursInYear = hoursInYear * (supporting / 100);
    
    let ancillarySavings = (ancillarySaving / 100) * ancillaryHoursInYear;
    let detractorSavings = (detractorsSaving / 100) * detractorHoursInYear;
    let supportingSavings = (supportingSaving / 100) * supportingHoursInYear;

    return Math.ceil(ancillarySavings + detractorSavings + supportingSavings);
};


export const calculateProductivityPotential = (sales, gainedProductivity, numberSalesPeople) => {

    if (gainedProductivity === 0) {
        return 0;
    }

    let value = sales * gainedProductivity * numberSalesPeople;
    
    return round(value);
};

export const calculateSales = (target, targetAttainment, hoursInWeek, vacation) => {
    
    if (hoursInWeek === 0) {
        return 0;
    }

    let workingDays = getWorkingDays(vacation);
    let hoursPerDay = hoursInWeek / 5;
    let hoursInYear = hoursPerDay * workingDays;
    
    let targetAttainmentPercentage = targetAttainment / 100;
    let actualAttainment = target * targetAttainmentPercentage;
    let value = actualAttainment / hoursInYear;
    
    return round(value);
};