export const formatCurrency = (currencySymbol, value, precision = 2) => {    
    let formattedNumber = formatNumber(value, precision);    
    return `${currencySymbol} ${formattedNumber}`;
};

export const formatNumber = (value, precision = 2) => {
    // Format to 2 decimal places if not a whole number
    if (value % 1 !== 0) {
        value = value.toFixed(precision);
    }
    return value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};