import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {AccountManagementCard} from './AccountManagementCard';
import {BusinessDevelopmentCard} from './BusinessDevelopmentCard';
import {CustomerSuccessCard} from './CustomerSuccessCard';
import {InsideSalesCard} from './InsideSalesCard';
import {SalesLeadershipCard} from './SalesLeadershipCard';
import {DemandGenerationCard} from './DemandGenerationCard';
import {CustomerServiceCard} from './CustomerServiceCard';

const JobRoles = ({ hasJobRole, theme, jobRole, setJobRole }) => {

    const { t : translations } = useTranslation();

    if (!hasJobRole) {
        return null;
    }

    return (
        <>
            <div className="row">
                <h4 className="text-center fw-bold my-4">
                    {translations('JobRole_Description')}
                </h4>
            </div>

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mb-4">
                <AccountManagementCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <BusinessDevelopmentCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <CustomerSuccessCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <InsideSalesCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <SalesLeadershipCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <DemandGenerationCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
                <CustomerServiceCard
                    primaryColour={theme.primary}
                    secondaryColour={theme.secondary}
                    jobRole={jobRole}
                    setJobRole={setJobRole}
                />
            </div>
        </>
    );
};

JobRoles.propTypes = {
    hasJobRole: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
    jobRole: PropTypes.string.isRequired,
    setJobRole: PropTypes.func.isRequired
};

export {
    JobRoles
};