import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConfirmDeleteModal = ({ text, show, onHide, onSubmit }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            backdrop="static"
            keyboard={false}
            centered={true}
            aria-labelledby="ConfirmDeleteModalLabel"
        >
            <Modal.Header closeButton={true} className="border-0">
                <Modal.Title id="ConfirmDeleteModalLabel" className="h3 fw-bold">
                    Confirmation required
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {text}
                </p>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <button className={'btn btn-min-width btn-secondary'} onClick={onHide}>
                    No
                </button>
                <button className={'btn btn-min-width btn-primary'} onClick={onSubmit}>
                    Yes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmDeleteModal.propTypes = {
    text: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export {
    ConfirmDeleteModal
};