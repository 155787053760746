import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import useValidatedField from 'hooks/useValidatedField';
import validationUtilities from 'utilities/validationUtilities';
import {ErrorFeedback} from 'components/ErrorFeedback';
import UserService from '../../services/userService';

const ForgotPassword = () => {

    const [email, setEmail, emailErrors] = useValidatedField('', [validationUtilities.isRequired, validationUtilities.isValidEmail]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [started, setStarted] = useState(false);
        
    const resetPassword = async () => {
        const response = await UserService.resetPassword({
            email: email
        });
        await response;
    };
    
    const submit = async (e) => {
        try {
            e.preventDefault();
            await resetPassword();
        } catch (ex) {
            console.log(ex);
        } finally {
            setStarted(true);
        }
    };
    
    useEffect(() => {
        let disable = false;
        if (email.length === 0 || emailErrors.length) {
            disable = true;
        }
        setDisableSubmit(disable);
    }, [emailErrors]);

    const styles = {
        backgroundImage: 'url(/img/login.png)',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: '600px'
    };
    
    return (
        <>
            <div className="container" style={styles}>
                <div className="container" style={{ maxWidth: '600px'}}>
                    <div className="str-card my-4 p-4">
                        <form onSubmit={submit}>
                            <h2 className="h3 mb-4">
                                Forgot Password
                            </h2>

                            <Message started={started} email={email} />
                            
                            <EmailForm 
                                started={started}
                                email={email}
                                setEmail={setEmail}
                                emailErrors={emailErrors}
                                submit={submit}
                                disableSubmit={disableSubmit}
                            />     
                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

/* eslint-disable react/prop-types */
const EmailForm = ({ started, email, setEmail, emailErrors, submit, disableSubmit }) => {
    if (started) {
        return null;
    }
    
    return (
        <>
            <p className="mb-4">
                Enter your email to start the password reset process
            </p>

            <div className="mb-4">
                <Form.Control
                    autoFocus={true}
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    isInvalid={emailErrors && emailErrors.length > 0}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <ErrorFeedback errors={emailErrors || []} />
            </div>

            <div className="mb-4">
                <p className="text-center m-0">
                    <Link to="/account/sign-in">
                        Back
                    </Link>
                </p>
            </div>

            <div className="text-center">
                <Button type="submit" className="text-uppercase" variant={'warning'} size={'lg'} onClick={submit} disabled={disableSubmit}>
                    Submit
                </Button>
            </div>
        </>
    );
};

/* eslint-disable react/prop-types */
const Message = ({ started, email }) => {
    if (!started) {
        return null;
    }
    return (
        <>
            <div className="mb-4">
                <p>
                    You have been sent an email containing instructions on how to reset your password. Please wait up to 5 minutes to receive the email.
                </p>
                <p>
                    If we have an account for {email} we’ll send a reset email
                </p>
                <p>
                    Please check your inbox. If you can’t find the email please check your junk folder.
                </p>
            </div>
            <div className="mb-4">
                <p className="text-center m-0">
                    <Link to="/account/sign-in">
                        Back
                    </Link>
                </p>
            </div>
        </>
    );
};

export {
    ForgotPassword
};