import {handleJsonResponse, httpClient} from './httpClient';

const API_URL = 'api/v1/users';

const hello = () => {
    return httpClient.get(`${API_URL}/hello`)
        .then(handleJsonResponse);
};

const heartbeat = () => {
    return httpClient.get(`${API_URL}/heartbeat`)
        .then(handleJsonResponse);
};

const login = (data) => {
    return httpClient.postJson(`${API_URL}/SignIn`, data)
        .then(handleJsonResponse);
};

const logout = (data) => {
    return httpClient.postJson(`${API_URL}/SignOut`, data)
        .then(handleJsonResponse);
};

const token = (data) => {
    return httpClient.postJson(`${API_URL}/token`, data)
        .then(handleJsonResponse);
};

const resetPassword = (data) => {
    return httpClient.postJson(`${API_URL}/reset-password`, data)
        .then(handleJsonResponse);
};

const changePassword = (data) => {
    return httpClient.postJson(`${API_URL}/change-password`, data)
        .then(handleJsonResponse);
};

export default {
    hello,
    heartbeat,
    login,
    logout,
    token,
    resetPassword,
    changePassword
};