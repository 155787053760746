import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {ErrorFeedback} from 'components/ErrorFeedback';
import ErrorBoundary from '../ErrorBoundary';

const NumericTextBox = ({ value, setValue, errors, min, max, step }) => {
    return (
        <ErrorBoundary>
            <Form.Control
                type="number"
                isInvalid={errors && errors.length > 0}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                min={min}
                max={max}
                step={step}
                style={{ border: 'none' }}
            />
            <ErrorFeedback errors={errors || []} />
        </ErrorBoundary>
    );
};

NumericTextBox.propTypes = {
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    errors: PropTypes.array,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired
};

export {
    NumericTextBox
};