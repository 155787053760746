export const ARTIFICIAL_DELAY = 400;

export const HEARTBEAT_DELAY = 1000 * 60;

//export const CARD_ICON_WIDTH = 125;
//export const CARD_ICON_HEIGHT = 175;
export const CARD_ICON_WIDTH = 150;
export const CARD_ICON_HEIGHT = 200;

// Hours in week slider settings
export const HOURS_MIN = 0;
export const HOURS_MAX = 60;
export const HOURS_STEP = 5;
export const HOURS_LABELS = {
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    60: 60,
};

// Number of sales people input range
export const PEOPLE_MIN = 1;
export const PEOPLE_MAX = 10000;
export const PEOPLE_STEP = 1;

// Target Attainment slider settings
export const ATTAINMENT_MIN = 0;
export const ATTAINMENT_MAX = 150;
export const ATTAINMENT_STEP = 5;
export const ATTAINMENT_LABELS = {
    0: '0%',
    10: '10%',
    20: '20%',
    30: '30%',
    40: '40%',
    50: '50%',
    60: '60%',
    70: '70%',
    80: '80%',
    90: '90%',
    100: '100%',
    110: '110%',
    120: '120%',
    130: '130%',
    140: '140%',
    150: '150%'
};
export const ATTAINMENT_LABELS_SHORT = {
    0: '0%',
    30: '30%',
    60: '60%',
    90: '90%',
    120: '120%',
    150: '150%'
};

// Sales Target slider settings
export const SALES_MIN = 100000;
export const SALES_MAX = 10000000;
export const SALES_STEP = 50000;
export const SALES_LABELS = {
    100000:  '100,000',
    2500000: '2,500,000',
    5000000: '5,000,000',
    7500000: '7,500,000',
    10000000: '10,000,000'
};
export const SALES_LABELS_SHORT = {
    100000:  '100K',
    2500000: '2.5M',
    5000000: '5.0M',
    7500000: '7.5M',
    10000000: '10M'
};

// Vacation input range
export const VACATION_MIN = 1;
export const VACATION_MAX = 340;
export const VACATION_STEP = 1;

export const AVAILABLE_THEMES = [{
    name: 'unbranded',
    primaryColour: '#2B62FF',
    secondaryColour: '#20243F',
    tertiaryColour: '#F0C851',
    coreColour: '#559B84',
    coreTextColour: '#FFFFFF',
    detractorColour: '#949494',
    detractorTextColour: '#FFFFFF',
    supportingColour: '#2B62FF',
    supportingTextColour: '#FFFFFF',
    ancillaryColour: '#F0C851',
    ancillaryTextColour: '#333333'
}, {
    name: 'kompetantly',
    primaryColour: '#912A8D',
    secondaryColour: '#912A8D',
    tertiaryColour: '#F79421',
    coreColour: '#333333',
    coreTextColour: '#FFFFFF',
    detractorColour: '#606060',
    detractorTextColour: '#FFFFFF',
    supportingColour: '#9B9B9B',
    supportingTextColour: '#FFFFFF',
    ancillaryColour: '#BCBCBC',
    ancillaryTextColour: '#FFFFFF'
}, {
    name: 'awful',
    primaryColour: '#FF0000',
    secondaryColour: '#000000',
    tertiaryColour: '#FFFF00',
    coreColour: '#FF66FF',
    coreTextColour: '#FFFFFF',
    detractorColour: '#FF00FF',
    detractorTextColour: '#FFFFFF',
    supportingColour: '#993399',
    supportingTextColour: '#FFFFFF',
    ancillaryColour: '#CC99CC',
    ancillaryTextColour: '#333333'
}];

export const DEFAULT_CURRENCY = 'USD';

export const AVAILABLE_CURRENCIES = [{
    code: 'USD',
    symbol: '$'
}, {
    code: 'EUR',
    symbol: '€'
}, {
    code: 'GBP',
    symbol: '£'
}, {
    code: 'JPY',
    symbol: '¥'
}, {
    code: 'CNY',
    symbol: '¥'
}];

export const AVAILABLE_CALCULATORS = [{
    name: 'Calculoid',
    ancillarySaving: 75,
    supportingSaving: 50,
    detractorsSaving: 100
}, {
    name: 'XLS',
    ancillarySaving: 50,
    supportingSaving: 25,
    detractorsSaving: 100
}, {
    name: 'Custom',
    ancillarySaving: 50,
    supportingSaving: 50,
    detractorsSaving: 50
}];


export const CALCULATOR_LABELS = {
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    60: 60,
    70: 70,
    80: 80,
    90: 90,
    100: 100
};

export const CONTROL_STATE = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    READY: 'READY',
    SUBMITTED: 'SUBMITTED',
    WAITING: 'WAITING'
};