import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import calculatorService from 'services/calculatorService';
import calculatorResultService from '../../services/calculatorResultService';
import { ARTIFICIAL_DELAY, AVAILABLE_THEMES, CONTROL_STATE } from 'settings';
import { ProductivityPotentialCard } from 'pages/root/components/ProductivityPotentialCard';
import { Header } from 'components/Header';
import { LoadingSpinner } from 'components/LoadingSpinner';

const ThankYou = () => {

    //Calculator Result
    const { id } = useParams();
    const [pageState, setPageState] = useState(CONTROL_STATE.LOADING);
    const [productivityPotential, setProductivityPotential] = useState(0);

    // Calculator Settings
    const [bannerTitle, setBannerTitle] = useState('');
    const [currency, setCurrency] = useState('$');
    const [theme, setTheme] = useState({
        organisationId: '',
        logo: '',
        primary: '#2B62FF',
        primaryText: '#FFFFFF',
        secondary: '#F0C851',
        secondaryText: '#FFFFFF',
        themeHeaderBgColour: '#20243F',
        themeHeaderColour: '#FFFFFF',
        themeBgColour: '#FFFFFF',
        themeColour: '#333333',
        themeFooterBgColour: '#20243F',
        themeFooterColour: '#FFFFFF',
        coreColour: '#559B84',
        coreTextColour: '#FFFFFF',
        detractorColour: '#949494',
        detractorTextColour: '#FFFFFF',
        supportingColour: '#2B62FF',
        supportingTextColour: '#FFFFFF',
        ancillaryColour: '#F0C851',
        ancillaryTextColour: '#333333'
    });

    const loadData = async () => {
        
        //Load Calculator Results
        const resultResponse = await calculatorResultService.getCalculatorResult(id);
        const calculatorResults = await resultResponse;
        setProductivityPotential(calculatorResults.productivityPotential);

        // Load calculator settings
        const calculatorResponse = await calculatorService.getCalculator(calculatorResults.calculatorId);
        const calculatorSettings = await calculatorResponse;
        setBannerTitle('Thank You for Submitting');
        setCurrency(calculatorSettings.currencySymbol);

        if (calculatorSettings.organisationId) {
            setTheme({
                organisationId: calculatorSettings.organisationId,
                logo: calculatorSettings.logo,
                primary: calculatorSettings.primary,
                primaryText: calculatorSettings.primaryText,
                secondary: calculatorSettings.secondary,
                secondaryText: calculatorSettings.secondaryText,
                themeHeaderBgColour: calculatorSettings.themeHeaderBgColour,
                themeHeaderColour: calculatorSettings.themeHeaderColour,
                themeBgColour: calculatorSettings.themeBgColour,
                themeColour: calculatorSettings.themeColour,
                themeFooterBgColour: calculatorSettings.themeFooterBgColour,
                themeFooterColour: calculatorSettings.themeFooterColour,
                coreColour: '#333333',
                coreTextColour: '#FFFFFF',
                detractorColour: '#606060',
                detractorTextColour: '#FFFFFF',
                supportingColour: '#9B9B9B',
                supportingTextColour: '#FFFFFF',
                ancillaryColour: '#BCBCBC',
                ancillaryTextColour: '#FFFFFF'
            });
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                setPageState(CONTROL_STATE.LOADING);
                await loadData();
            } catch (e) {
                //console.error(e);
            } finally {
                setPageState(CONTROL_STATE.READY);
            }
        };

        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }, ARTIFICIAL_DELAY);

    }, []);

    if (pageState === 'LOADING') {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <>
            <Header title={bannerTitle} theme={theme} />

            <div className="container">

                <div className="str-card position-relative my-4 p-4">

                    <ProductivityPotentialCard
                        isPublic={true}
                        currencySymbol={currency}
                        theme={theme}
                        productivityPotential={productivityPotential}
                    />

                </div>
            </div>
        </>
    );
};

export {
    ThankYou
};

