import React from 'react';
import PropTypes from 'prop-types';

const PlusButton = ({ colour, onClick }) => {

    const buttonStyle = {
        width: '2.5rem',
        backgroundColor: colour,
        border: 'none',
        borderRadius: '0.25rem'
    };
    
    return (
        <button className="btn btn-info" type="button" style={buttonStyle} onClick={onClick}>
            <i className="fa fa-plus" />
        </button>
    );
};

PlusButton.propTypes = {
    colour: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export {
    PlusButton
};



