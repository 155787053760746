import React from 'react';
import { Outlet } from 'react-router-dom';
import {AdminHeader} from '../components/AdminHeader';

const AdminLayout = () => {
    return (
        <>
            <AdminHeader />
            <Outlet />
        </>
    );
};

export {
    AdminLayout
};
