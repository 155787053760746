import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const SalesIcon = ({ primaryColour, tertiaryColour }) => {
    return (
        <svg width={CARD_ICON_WIDTH} height={CARD_ICON_HEIGHT} viewBox="0 0 200 224.571">
            <defs>
                <clipPath id="clip-path">
                    <rect width="200" height="224.571" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="200" height="217.303" fill="none"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
                <g>
                    <g clipPath="url(#clip-path)">
                        <g transform="translate(0 7.268)" opacity="0.75"  style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                            <g clipPath="url(#clip-path-3)">
                                <path d="M200,180.94V116.364a44.076,44.076,0,0,0-22.038-38.17L122.038,45.9a44.075,44.075,0,0,0-44.075,0L22.038,78.193A44.076,44.076,0,0,0,0,116.364V180.94a44.076,44.076,0,0,0,22.038,38.17L77.962,251.4a44.075,44.075,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,180.94" transform="translate(0 -40)" fill="#e8e9e8"/>
                            </g>
                        </g>
                        <path d="M200,140.94V76.364a44.076,44.076,0,0,0-22.038-38.17L122.038,5.9a44.076,44.076,0,0,0-44.075,0L22.038,38.193A44.076,44.076,0,0,0,0,76.364V140.94a44.076,44.076,0,0,0,22.038,38.17L77.962,211.4a44.076,44.076,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,140.94" transform="translate(0 0)" fill="#fff"/>
                        <path d="M368.687,395.5c2.413-7.608,6.37-8.166,6.37-8.166q.055-1.179.055-2.373a49.985,49.985,0,1,0-4.839,21.491s-3.359-5.359-1.586-10.952m-6.806,5a39.957,39.957,0,0,1-7.3,11.378l-4.319-4.319-2.57,2.57,4.319,4.319a39.95,39.95,0,0,1-11.379,7.3,39.655,39.655,0,0,1-7.48,2.322,40.151,40.151,0,0,1-6.239.77v-6.109h-3.634v6.109a40.151,40.151,0,0,1-6.24-.77,39.649,39.649,0,0,1-14.257-6,40.261,40.261,0,0,1-4.6-3.626l4.319-4.319-2.57-2.57-4.319,4.319a39.943,39.943,0,0,1-7.3-11.378,39.629,39.629,0,0,1-2.322-7.48,40.149,40.149,0,0,1-.77-6.239h6.109v-3.634h-6.109a40.147,40.147,0,0,1,.77-6.239,39.659,39.659,0,0,1,6-14.257,40.307,40.307,0,0,1,3.626-4.6l4.319,4.319,2.57-2.569-4.319-4.319a39.946,39.946,0,0,1,11.379-7.3,39.643,39.643,0,0,1,7.48-2.322,40.1,40.1,0,0,1,6.24-.77v6.109h3.634v-6.109a40.1,40.1,0,0,1,6.239.77,39.653,39.653,0,0,1,14.257,6,40.267,40.267,0,0,1,4.6,3.626l-4.319,4.319,2.57,2.569,4.319-4.319a39.957,39.957,0,0,1,7.3,11.378,39.672,39.672,0,0,1,2.322,7.48,40.1,40.1,0,0,1,.77,6.239h-6.109v3.634h6.109a40.1,40.1,0,0,1-.77,6.239,39.67,39.67,0,0,1-2.322,7.48" transform="translate(-225.092 -274.084)" fill={primaryColour}/>
                        <path d="M526,440.505l-5.409,33.146,24.656,16.94-14.5-21.108Z" transform="translate(-426.003 -360.466)" fill={tertiaryColour}/>
                        <path d="M827.009,638.121a10.839,10.839,0,1,1-10.839-10.839,10.839,10.839,0,0,1,10.839,10.839" transform="translate(-659.004 -513.306)" fill={tertiaryColour}/>
                        <path d="M776.167,759.061c-.189,0-.378.005-.566.011s-.376-.011-.566-.011a19.281,19.281,0,0,0-19.281,19.281v3.333h39.694v-3.333a19.281,19.281,0,0,0-19.281-19.281" transform="translate(-618.435 -621.141)" fill={tertiaryColour}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

SalesIcon.propTypes = {
    primaryColour: PropTypes.string,
    tertiaryColour: PropTypes.string
};

export {
    SalesIcon
};