import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const DemandGenerationIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 97 97">
            <g>
                <path fill={primaryColour} d="M63.28,43.06c0-3.16-.51-6.29-1.51-9.29l-1.62,1.62c-1.17,1.18-2.77,1.84-4.44,1.84-.18,0-.35-.03-.53-.05,3.24,11.96-3.83,24.28-15.78,27.52-11.96,3.24-24.28-3.83-27.52-15.78-3.24-11.96,3.83-24.28,15.78-27.52,4.15-1.13,8.54-1.03,12.64,.27,.03-1.61,.68-3.15,1.82-4.29l2.04-2.04c-15.32-5.88-32.5,1.77-38.38,17.09-4.93,12.85-.39,27.41,10.98,35.17l-4.89,13.05c-.53,1.41,.19,2.99,1.6,3.52,1.41,.53,2.99-.19,3.52-1.6h0l4.61-12.29c3.21,1.41,6.64,2.23,10.13,2.44v8.89c0,1.51,1.21,2.74,2.72,2.75,1.51,0,2.74-1.21,2.75-2.72v-9.08c3.44-.42,6.77-1.45,9.85-3.02l4.89,13.03c.53,1.41,2.1,2.13,3.52,1.6,1.41-.53,2.13-2.1,1.6-3.52l-5.29-14.11c7.27-5.62,11.53-14.28,11.53-23.47Zm-.6-20.06l-5.18-1.99c-.46-.18-.81-.54-.99-.99l-1.99-5.18c-.34-.89-1.34-1.33-2.23-.99-.23,.09-.43,.22-.6,.39l-6.34,6.34c-.48,.48-.63,1.2-.39,1.84l1.67,4.34-10.9,10.9c-3-1.22-6.42,.21-7.65,3.21-1.22,3,.21,6.42,3.21,7.65s6.42-.21,7.65-3.21c.58-1.42,.58-3.01,0-4.43l10.49-10.49,5.66,2.17c.64,.24,1.36,.09,1.84-.39l6.34-6.34c.67-.67,.68-1.76,0-2.44-.17-.17-.38-.31-.6-.39h0Zm-29.14,9.59c.45,0,.91,.04,1.36,.11l5.8-5.8c-2.24-1.04-4.68-1.59-7.16-1.59-9.81-.19-17.91,7.6-18.1,17.41s7.6,17.91,17.41,18.1c9.81,.19,17.91-7.6,18.1-17.41,0-.12,0-.23,0-.35,0-2.4-.48-4.78-1.41-6.99l-5.92,5.92c.03,.35,.05,.71,.05,1.07-.19,5.6-4.87,9.98-10.47,9.79-5.6-.19-9.98-4.87-9.79-10.47,.18-5.46,4.66-9.8,10.13-9.8h0Z" />
                <path fill={secondaryColour} d="M88.61,56.22c0,4.71-3.82,8.53-8.53,8.53s-8.53-3.82-8.53-8.53c0-4.71,3.82-8.53,8.53-8.53h0c4.71,0,8.53,3.82,8.53,8.53Z" />
                <path fill={secondaryColour} d="M80.53,66.53c-.15,0-.3,0-.45,0s-.3,0-.45,0c-8.38,0-15.18,6.79-15.18,15.18v2.62h31.24v-2.62c0-8.38-6.79-15.18-15.17-15.18Z" />
            </g>
        </svg>
    );
};

DemandGenerationIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    DemandGenerationIcon
};