import React from 'react';
import PropTypes from 'prop-types';
import {SalesIcon} from 'components/icons/SalesIcon';
import {formatCurrency} from 'utilities/formatUtilities';
import {useTranslation} from 'react-i18next';

const SalesCard = ({ isManager, theme, sales, currencySymbol }) => {

    const { t : translations } = useTranslation();
    
    const description = isManager
        ? translations('Sales_Card_Public')
        : translations('Sales_Card_Private');
    
    const formattedSales = formatCurrency(currencySymbol, sales, 0);
    
    return (
        <div className="col">
            <div className="card h-100 mb-3" style={{ backgroundColor: '#F1F1F1' }}>
                <div className="card-body text-center">
                    <SalesIcon primaryColour={theme.primary} tertiaryColour={theme.secondary} />
                    <h4 className="card-title fw-bold my-3 mx-auto w-75">
                        {description}
                    </h4>
                    <p className="card-text fs-1" style={{ color: theme.primary }}>
                        {formattedSales}
                    </p>
                </div>
            </div>
        </div>
    );
};

SalesCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    sales: PropTypes.number,
    currencySymbol: PropTypes.string
};

export {
    SalesCard
};