import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const SupportingModal = ({ theme, show, onHide }) => {

    const { t : translations } = useTranslation();
    const backgroundColour = theme.supportingColour;
    const textColour = theme.supportingTextColour;
    
    return (
        <Modal show={show}
            onHide={onHide}
            size="md"
            backdrop="static"
            keyboard={true}
            centered={true}
            aria-labelledby="SupportingCardLabel"
        >
            <Modal.Header closeButton={false} className="border-0" style={{ backgroundColor: backgroundColour }}>
                <p className="text-end w-100 m-0">
                    <i className="fas fa-2x fa-times text-end cursor-pointer" style={{ color: textColour }} onClick={onHide}></i>
                </p>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: backgroundColour, color: textColour, padding: '0 2rem 2rem' }}>
                <h5 className="text-center fw-bold mb-4" style={{ color: textColour }}>
                    <i className="fa fa-info align-middle" style={{ border: '2px solid ' + textColour, borderRadius: '50%', width: '2em', height: '2em', paddingTop: '0.4em' }}></i>
                    <span className="text-uppercase ps-3">
                        {translations('Supporting')}
                    </span>
                </h5>
                <p className="m-0">
                    {translations('Supporting_Description')}                    
                </p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: backgroundColour }}></Modal.Footer>
        </Modal>
    );
};

SupportingModal.propTypes = {
    theme: PropTypes.object,
    show: PropTypes.bool,
    onHide: PropTypes.func
};

export {
    SupportingModal
};
