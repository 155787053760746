import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const OptionalSubmit = ({ isPublic, onClick, theme }) => {
    
    const { t : translations } = useTranslation();
    
    if (isPublic) {
        return null;
    }
    
    return (
        <div className="mt-4 text-center">
            <button className="btn btn-lg text-uppercase" onClick={onClick} style={{ backgroundColor: theme.primary, color: theme.primaryText}}>
                {translations('Submit_Calculation')}
            </button>
        </div>
    );
};

OptionalSubmit.propTypes = {
    isPublic: PropTypes.bool,
    onClick: PropTypes.func,
    theme: PropTypes.object
};

export {
    OptionalSubmit
};