import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const CustomerServiceIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 117.69 126.88">
            <g>
                <path fill={primaryColour} d="M69.18,88.8c0,8.12,2.8,14.7,6.26,14.7,1.68,0,3.21-1.57,4.33-4.1,.18,.05,.36,.08,.55,.09,2.51,0,4.55-4.78,4.55-10.68s-2.03-10.68-4.55-10.68c-.19,0-.37,.03-.55,.08-.41-.97-.97-1.87-1.65-2.67v-1.48c.02-16.45-13.3-29.79-29.75-29.81-16.45-.02-29.79,13.3-29.81,29.75v2.02c-.49,.68-.91,1.41-1.24,2.18-.18-.05-.36-.08-.55-.08-2.51,0-4.55,4.78-4.55,10.68s2.03,10.68,4.55,10.68c.19,0,.37-.03,.55-.09,1.13,2.54,2.65,4.1,4.33,4.1,.68-.02,1.34-.25,1.87-.67,2.63,2.84,7.87,6.89,16.89,8.05,.75,2.12,3.07,3.23,5.19,2.48,2.12-.75,3.23-3.07,2.48-5.19-.75-2.12-3.07-3.23-5.19-2.48-.95,.34-1.75,1.02-2.23,1.91-8.47-1.13-13.04-5.01-15.09-7.37,1.64-3.58,2.44-7.49,2.32-11.42,0-6.14-1.6-11.39-3.88-13.59v-1.15c0-6.03,2.24-11.84,6.29-16.31,1.08,.42,2.3,.23,3.21-.49,8.67-6.91,20.97-6.91,29.64,0,.58,.46,1.3,.72,2.04,.72,.4,0,.79-.08,1.16-.23,4.06,4.46,6.3,10.28,6.3,16.31v1.59c-2.06,2.41-3.48,7.38-3.48,13.15Z" />
                <path fill={secondaryColour} d="M56.35,70.27c0,4.51-3.65,8.16-8.16,8.16s-8.16-3.65-8.16-8.16,3.65-8.16,8.16-8.16h0c4.51,0,8.16,3.65,8.16,8.16Z" />
                <path fill={secondaryColour} d="M48.62,80.13c-.14,0-.28,0-.43,0s-.28,0-.43,0c-8.02,0-14.52,6.5-14.52,14.52h0v2.51h29.89v-2.51c0-8.02-6.5-14.52-14.52-14.52Z" />
            </g>
        </svg>
    );
};

CustomerServiceIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    CustomerServiceIcon
};