import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const BusinessDevelopmentIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 97 97">
            <g>
                <path fill={secondaryColour} d="M82.41,44.86c0,4.38-3.55,7.93-7.93,7.93-4.38,0-7.93-3.55-7.93-7.93,0-4.38,3.55-7.93,7.93-7.93s7.93,3.55,7.93,7.93h0Z" />
                <path fill={secondaryColour} d="M74.89,54.45c-.14,0-.28,0-.41,0s-.28,0-.41,0c-7.79,0-14.11,6.32-14.11,14.11v2.44h29.05v-2.44c0-7.79-6.32-14.11-14.11-14.11Z" />
                <path fill={primaryColour} d="M63.43,55c-.08-.77-.46-1.48-1.06-1.96l-18.61-15.12c-.28-.2-.63-.27-.97-.2h0l-6.38,1.39c-2.73,.59-5.55-.46-7.22-2.7l-.35-.47c-.58-.78-.42-1.87,.36-2.45,.15-.11,.32-.2,.5-.26l5.89-1.94-.74-.6-18.13-4.67c-.15-.04-.31,.02-.39,.15l-8.4,13.05c-.09,.14-.07,.32,.04,.44l3.76,3.83,2.46,2.32,3.75-3.2c1.69-1.45,4.24-1.25,5.68,.44,.04,.05,.08,.1,.12,.15-1.07,.47-1.56,1.72-1.09,2.79,.41,.94,1.43,1.45,2.42,1.22,1.27,.08,2.45,.68,3.26,1.66,1.21,1.46,1.41,3.5,.51,5.17,.13,0,.25,0,.38,0,2.25,.17,3.95,2.13,3.78,4.38-.04,.6-.22,1.19-.52,1.72,2.04,.14,3.58,1.91,3.44,3.96-.04,.59-.22,1.16-.53,1.67l2.94,2.77,2.02,1.64c1.06,.86,2.61,.7,3.47-.36,.86-1.06,.7-2.61-.36-3.47h0l-2.74-2.23c-.28-.22-.32-.63-.1-.91,.22-.28,.63-.32,.91-.1l5.7,4.63c1.2,.97,2.93,.84,3.98-.29,1.09-1.18,1.01-3.02-.17-4.11-.04-.04-.09-.08-.14-.12l-7.07-5.75c-.26-.25-.26-.66-.02-.92,.22-.23,.57-.26,.83-.08l7.91,6.43c.7,.56,1.59,.83,2.48,.73,.89-.09,1.7-.54,2.26-1.25,1.14-1.44,.92-3.54-.51-4.7l-8.81-7.21c-.28-.23-.32-.63-.09-.91,.11-.13,.26-.22,.43-.23,.17-.02,.34,.03,.48,.14l8.6,7.04,1.27,1.04c1.25,1.02,3.08,.83,4.1-.42,.49-.6,.72-1.37,.64-2.14h0Z" />
                <path fill={primaryColour} d="M29.13,55.47c-.07,0-.14,0-.21,0-.66,0-1.3,.25-1.78,.69l-3.42,3.13c-1.05,1.18-1.06,2.95-.03,4.15,.42,.55,1.05,.9,1.73,.97,.54,.04,1.07-.15,1.47-.52l1.65-1.61c.16-.26,.35-.5,.57-.71l.35-.37c.25-.25,.52-.46,.82-.63l.51-.5c1.06-1.03,1.07-2.73,.04-3.78-.45-.46-1.06-.75-1.71-.8h0Z" />
                <path fill={primaryColour} d="M32.25,61.57c-.63,0-1.23,.25-1.66,.7l-.39,.41c-.45,.42-.7,1.01-.7,1.63-.03,.76,.25,1.5,.76,2.05,.61,.76,1.72,.89,2.48,.28,.02-.01,.03-.03,.05-.04l1.1-1.15c.87-.92,.84-2.37-.07-3.24-.42-.4-.98-.63-1.57-.64h0Z" />
                <path fill={primaryColour} d="M27.25,49.79c-.56-.68-1.38-1.1-2.26-1.15-.07,0-.15,0-.22,0-.81,0-1.58,.3-2.18,.85l-4.29,3.96c-1.19,1.29-1.28,3.25-.22,4.65,.48,.71,1.24,1.18,2.09,1.3,.66,.07,1.32-.16,1.8-.61l4.98-4.56c1.27-1.16,1.4-3.11,.3-4.43h0Z" />
                <path fill={primaryColour} d="M20.83,43.08c-.71-.08-1.42,.15-1.96,.61l-5.9,5.04c-.82,1.14-.8,2.69,.04,3.81,.41,.6,1.06,1,1.79,1.1,.56,.06,1.12-.13,1.52-.52l6-5.54c.99-.92,1.11-2.45,.29-3.52-.44-.56-1.08-.92-1.79-.98Z" />
                <path fill={primaryColour} d="M72.05,30.16c-.06-.19-.26-.29-.45-.25l-13.85,3.23c-1.13,.28-2.31,.24-3.42-.1l-9.81-2.99c-.67-.21-1.4-.2-2.07,.02l-12.52,4.11c-.38,.12-.59,.53-.46,.91,.02,.07,.06,.15,.11,.21l.35,.47c1.43,1.92,3.84,2.82,6.17,2.31l6.38-1.39c.16-.04,.33-.05,.49-.05,.51,0,1.01,.18,1.42,.5l13.7,10.86c1.87,1.48,4.35,1.93,6.61,1.18h.03c-1.08-2.69-1.04-5.69,.11-8.33,1.4-3.62,4.82-6.05,8.7-6.17l-1.48-4.52Z" />
            </g>
        </svg>
    );
};

BusinessDevelopmentIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    BusinessDevelopmentIcon
};