const isRequired = (value) => {

    if (value === undefined || value === null) {
        return 'Field is required';
    }

    if (value.trim().length === 0) {
        return 'Field is required';
    }

    return null;
};

const isHtmlRequired = (value) => {

    if (value === undefined || value === null) {
        return 'Field is required';
    }

    let rawHtml = value.replace(/<p><\/p>/gi, '');
    if (rawHtml.trim().length <= 3) {
        return 'Field is required';
    }

    return null;
};


const isDateRequired = (value) => {

    if (value === undefined || value === null) {
        return 'Field is required';
    }

    return null;
};

const isValidEmail = (value) => {

    if (value === undefined || value === null || validEmail(value)) {
        return null;
    }

    return 'Please enter a valid email address';
};

const validEmail = (value) => {
    /* eslint-disable */
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(value.toLowerCase());

    /*return value
        .toLowerCase()
        .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);*/
};

const max = (value, limit = 10) => {
    if (value === undefined || value != null && value.trim().length <= limit){
        return null;
    }
    return `Field must be less than or equal to ${limit} characters`;
};

const matchesPassword = (value, source) => {
    if (value === undefined || value == null || value === source) {
        return null;
    }
    return `Passwords do not match`;
};

export default {
    isRequired,
    isHtmlRequired,
    isDateRequired,
    isValidEmail,
    max,
    validEmail,
    matchesPassword
};