import React from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const AlertModal = ({ show, onHide, onExited, title, body }) => {
    return (
        <Modal 
            show={show}
            onHide={onHide}
            onExited={onExited}
            backdrop="static"
            keyboard={false}
            centered={true}
            aria-labelledby="AlertModalLabel"
        >
            <Modal.Header closeButton={true} className="border-0">
                <Modal.Title id="AlertModalLabel" className="h3 fw-bold">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <button className={'btn btn-secondary'} onClick={onHide}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

AlertModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
};

export {
    AlertModal
}; 