import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import userService from '../services/userService';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [authorizing, setAuthorizing] = useState(false);
    const [authorized, setAuthorized] = useState(false);

    const signIn = async (email, password) => {
        setAuthorizing(true);
        const response = await userService.token({
            email: email,
            password: password
        });
        const data = await response;
        const { token } = data;
        localStorage.setItem('accessToken', token);
        setAuthorized(true);
        setAuthorizing(false);
    };

    const signOut = async () => {
        setAuthorized(false);
        localStorage.removeItem('accessToken');
    };
    
    const refreshToken = async () => {
        setAuthorizing(true);
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            setAuthorized(true);
        }
        setAuthorizing(false);
    };

    return (
        <AuthContext.Provider value={{ authorized, authorizing, signIn, signOut, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.any
};

export default AuthContext;