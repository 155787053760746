import React from 'react';
import PropTypes from 'prop-types';
import {formatCurrency} from 'utilities/formatUtilities';
import {ProductivityPotentialIcon} from 'components/icons/ProductivityPotentialIcon';
import {useTranslation} from 'react-i18next';

const ProductivityPotentialCard = ({ isPublic, currencySymbol, theme, productivityPotential }) => {

    const { t : translations } = useTranslation();
    const revenueIncrease = formatCurrency(currencySymbol, productivityPotential, 0);
    
    if (!isPublic) {
        return null;
    }
    
    return (
        <div className="card" style={{ backgroundColor: theme.themeFooterBgColour }}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-lg-6 text-center mb-3 mb-lg-0">
                        <ProductivityPotentialIcon primaryColour={theme.primary} tertiaryColour={theme.secondary} />
                        <h4 className="card-text" style={{ color: theme.themeFooterColour}}>
                            {translations('Productivity_Potential_Card_Title')}
                        </h4>
                    </div>
                    <div className="col-12 col-lg-6 text-center mb-3 mb-lg-0 d-flex">
                        <h3 className="card-text text-start align-self-center fs-3" style={{ color: theme.themeFooterColour}}>
                            {translations('Productivity_Potential_Card_Description', { revenueIncrease : revenueIncrease })}
                        </h3>
                    </div>
                </div>         
            </div>
        </div>
    );
};

ProductivityPotentialCard.propTypes = {
    isPublic: PropTypes.bool,
    currencySymbol: PropTypes.string,
    theme: PropTypes.object,
    productivityPotential: PropTypes.number
};

export {
    ProductivityPotentialCard
};

