import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

const AdminHeader = ({ title = 'Strategy to Revenue Productivity Calculator' }) => {
    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF' }}>
                <div className="container">
                    <header>
                        <div style={{ height: '120px', margin: '0 auto' }}>
                            <Link to="/">
                                <img src="/str-logo-colour-s.png" height="100%" alt="str-logo-colour-s" title="str-logo-colour-s" style={{ padding: '20px 0'}} />
                            </Link>
                        </div>
                    </header>
                </div>
            </div>
            <div className="banner" style={{ backgroundColor: '#20243F', borderColor: '#F0C851' }}>
                <h1 className="text-center py-4" style={{ color: '#FFFFFF' }}>
                    {title}
                </h1>
            </div>
        </>
    );
};

AdminHeader.propTypes = {
    title: PropTypes.string
};

export {
    AdminHeader
};
