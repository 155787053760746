import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {RootIndex} from './pages/root/RootIndex';
import {AdminIndex} from './pages/admin/AdminIndex';
import {AddCalculator} from './pages/admin/calculators/AddCalculator';
import { EditCalculator } from './pages/admin/calculators/EditCalculator';
import { ThankYou } from './pages/messages/ThankYou';
import { Unpublished } from './pages/messages/Unpublished';
import {RequiresAuth} from './components/RequiresAuth';
import {SignIn} from './pages/account/SignIn';
import {ChangePassword} from './pages/account/ChangePassword';
import {ForgotPassword} from './pages/account/ForgotPassword';
import {Error404} from './pages/errors/Error404';
import {AdminLayout} from './layouts/AdminLayout';

const App = () => {
    return (
        <Routes>
            
            <Route path="/">

                <Route index element={<RootIndex />} />
                <Route path=":hash" element={<RootIndex />} />

                <Route path="account" element={<AdminLayout />}>
                    <Route path="sign-in" element={<SignIn />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
                
                <Route path="admin" element={<AdminLayout />}>
                    <Route element={<RequiresAuth />}>
                        <Route index element={<AdminIndex />} />
                        <Route path="calculators">
                            <Route path="add" element={<AddCalculator />} />
                            <Route path=":id/edit" element={<EditCalculator />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Error404 />} />
                </Route>

                <Route path="messages">
                    <Route path=":id/ThankYou" element={<ThankYou />} />
                    <Route path="Unpublished" element={<Unpublished />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
                
            </Route>
            
            {/* catch all */}
            <Route path="*" element={<Error404 />} />
            
        </Routes>
    );
};

export default App;