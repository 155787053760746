import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import ErrorBoundary from '../ErrorBoundary';
import 'react-quill/dist/quill.snow.css';

const RichTextArea = ({ value, setValue, errors }) => {
    return (
        <ErrorBoundary>
            <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
            />
            { errors.length > 0 ? 
                <div className="text-danger" style={{ marginTop: '0.25rem', fontSize: '0.875em'}}><p>Field is required</p></div>
                : null
            }
        </ErrorBoundary>
    );
};

RichTextArea.propTypes = {
    value: PropTypes.any,
    setValue: PropTypes.func,
    errors: PropTypes.array
};

export {
    RichTextArea
};