import {useState} from 'react';
import {ARTIFICIAL_DELAY} from '../settings';

const later = (delay) => {
    return new Promise(resolve => setTimeout(resolve, delay));
};

const useDelay = () => {

    const [delayEnded, setDelayEnded] = useState(false);

    const startDelay = async (delay = ARTIFICIAL_DELAY) => {
        await later(delay);
        setDelayEnded(true);
    };

    return {
        startDelay,
        delayEnded
    };
};

export default useDelay;
