import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const GainedProductivityIcon = ({ primaryColour, tertiaryColour }) => {
    return (
        <svg width={CARD_ICON_WIDTH} height={CARD_ICON_HEIGHT} viewBox="0 0 200 224.571">
            <defs>
                <clipPath id="clip-path">
                    <rect width="200" height="224.571" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect width="200" height="217.303" fill="none"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip-path)">
                <g>
                    <g clipPath="url(#clip-path)">
                        <g transform="translate(0 7.268)" opacity="0.75" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                            <g clipPath="url(#clip-path-3)">
                                <path d="M200,180.94V116.364a44.076,44.076,0,0,0-22.038-38.17L122.038,45.9a44.075,44.075,0,0,0-44.075,0L22.038,78.193A44.076,44.076,0,0,0,0,116.364V180.94a44.076,44.076,0,0,0,22.038,38.17L77.962,251.4a44.075,44.075,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,180.94" transform="translate(0 -40)" fill="#e8e9e8"/>
                            </g>
                        </g>
                        <path d="M200,140.94V76.364a44.076,44.076,0,0,0-22.038-38.17L122.038,5.9a44.076,44.076,0,0,0-44.075,0L22.038,38.193A44.076,44.076,0,0,0,0,76.364V140.94a44.076,44.076,0,0,0,22.038,38.17L77.962,211.4a44.076,44.076,0,0,0,44.075,0l55.925-32.288A44.076,44.076,0,0,0,200,140.94" transform="translate(0 0)" fill="#fff"/>
                        <path d="M340.99,405.439a39.747,39.747,0,0,1-20.537,6.785V406.1h-3.641v6.121a40.222,40.222,0,0,1-6.252-.772,39.726,39.726,0,0,1-14.285-6.013,40.312,40.312,0,0,1-4.61-3.633l4.327-4.327-2.575-2.575-4.327,4.327a40.028,40.028,0,0,1-7.319-11.4,39.729,39.729,0,0,1-2.326-7.495,40.218,40.218,0,0,1-.772-6.252h6.121v-3.641h-6.121a40.218,40.218,0,0,1,.772-6.252,39.725,39.725,0,0,1,6.013-14.285,40.318,40.318,0,0,1,3.633-4.61l4.327,4.327,2.575-2.574-4.327-4.327a40.026,40.026,0,0,1,11.4-7.319,39.73,39.73,0,0,1,7.495-2.326,40.219,40.219,0,0,1,6.252-.772v6.121h3.641V332.3a40.219,40.219,0,0,1,6.252.772,39.725,39.725,0,0,1,14.285,6.013,40.311,40.311,0,0,1,4.61,3.633l-4.327,4.327,2.574,2.574,4.327-4.327a40.034,40.034,0,0,1,7.319,11.4,39.726,39.726,0,0,1,2.326,7.495,40.219,40.219,0,0,1,.772,6.252h-6.121v3.641h6.121q-.026.589-.07,1.176l10.161-.04c.045-.872.068-2.074.068-2.957a50.116,50.116,0,1,0-17.957,38.44l-8.893.078.018-4.845Z" transform="translate(-219.726 -263.612)" fill={primaryColour}/>
                        <path d="M519.94,427.916l-5.42,33.211,24.7,16.974-14.531-21.15Z" transform="translate(-421.033 -350.165)" fill={tertiaryColour}/>
                        <path d="M825.762,713.793l-2.509-.815q.064-.64.065-1.3t-.065-1.3l2.509-.815a.88.88,0,0,0,.563-1.106l-1.148-3.533a.88.88,0,0,0-1.106-.563l-2.508.815a12.954,12.954,0,0,0-1.527-2.1l1.552-2.135a.88.88,0,0,0-.194-1.226l-3.005-2.183a.88.88,0,0,0-1.225.194l-1.55,2.133a12.8,12.8,0,0,0-2.469-.8v-2.637a.88.88,0,0,0-.877-.877h-3.715a.88.88,0,0,0-.878.877v2.637a12.805,12.805,0,0,0-2.469.8l-1.55-2.133a.88.88,0,0,0-1.226-.194l-3.005,2.183a.88.88,0,0,0-.194,1.226l1.552,2.135a12.931,12.931,0,0,0-1.527,2.1l-2.509-.815a.88.88,0,0,0-1.106.563l-1.148,3.533a.88.88,0,0,0,.563,1.106l2.509.815q-.064.64-.065,1.3t.065,1.3l-2.509.815a.88.88,0,0,0-.563,1.106l1.148,3.533a.88.88,0,0,0,1.106.563l2.509-.815a12.928,12.928,0,0,0,1.527,2.1l-1.552,2.135a.88.88,0,0,0,.194,1.226l3.005,2.183a.88.88,0,0,0,1.226-.194l1.55-2.133a12.805,12.805,0,0,0,2.469.8v2.637a.88.88,0,0,0,.878.877h3.715a.88.88,0,0,0,.877-.877V724.3a12.8,12.8,0,0,0,2.469-.8l1.55,2.133a.88.88,0,0,0,1.225.194l3.005-2.183a.88.88,0,0,0,.194-1.226l-1.552-2.135a12.951,12.951,0,0,0,1.527-2.1l2.508.815a.88.88,0,0,0,1.106-.563l1.148-3.533a.88.88,0,0,0-.563-1.106m-15.352,3.846a5.958,5.958,0,1,1,5.959-5.959,5.959,5.959,0,0,1-5.959,5.959" transform="translate(-650.102 -569.169)" fill={primaryColour}/>
                        <path d="M838.552,616.747a2.262,2.262,0,0,1,2.313.879l.92,1.266q.294-.1.592-.192v-1.565a2.266,2.266,0,0,1,2.263-2.263h3.715a2.257,2.257,0,0,1,1.709.784v-17H838.552Z" transform="translate(-686.189 -489.878)" fill={tertiaryColour}/>
                        <path d="M770.2,659.13c0-.1,0-.207,0-.311s0-.207,0-.31l-1.49-.484a2.266,2.266,0,0,1-1.453-2.852l1.148-3.533a2.264,2.264,0,0,1,2.852-1.453l1.49.484q.177-.255.365-.5l-.921-1.268a2.265,2.265,0,0,1,.5-3.161l1.6-1.16V630.951H762.774v29.343h4.919a2.244,2.244,0,0,1,1.015-.68Z" transform="translate(-624.18 -516.309)" fill={tertiaryColour}/>
                        <rect width="11.512" height="23.023" transform="translate(124.825 120.962)" fill={tertiaryColour}/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

GainedProductivityIcon.propTypes = {
    primaryColour: PropTypes.string,
    tertiaryColour: PropTypes.string
};

export {
    GainedProductivityIcon
};