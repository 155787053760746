import React from 'react';
import PropTypes from 'prop-types';
import {Footer} from 'components/Footer';

const OptionalFooter = ({ isWindowed, theme }) => {
    if (isWindowed) {
        return null;
    }
    return (
        <Footer theme={theme} />
    );
};

OptionalFooter.propTypes = {
    isWindowed: PropTypes.bool,
    theme: PropTypes.object
};

export {
    OptionalFooter
};