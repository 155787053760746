import React, {useState} from 'react';
import {Toast} from 'react-bootstrap';
import PropTypes from 'prop-types';

const Toasty = ({ title, body }) => {

    const [show, setShow] = useState(true);

    return (
        <Toast onClose={() => setShow(false)} show={show}>
            <Toast.Header className="bg-primary text-white">
                <strong className="me-auto">{title}</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body className="bg-white">{body}</Toast.Body>
        </Toast>
    );
};

Toasty.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string
};

export {
    Toasty
};