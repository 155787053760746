import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ isPublic = true }) => {
    if (isPublic) {
        return (
            <div className="container pt-4 d-flex" style={{ height: 'calc(100vh - 83px)'}}>
                <div className="mx-auto align-self-center position-relative">
                    <div className="spinner-border fs-1" style={{ width: '6rem', height: '6rem', color: '#F0C851' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <i className="fas fa-calculator fa-3x position-absolute top-50 start-50 translate-middle" style={{ color: '#2B62FF' }}></i>
                </div>
            </div>
        );
    }
    return (
        <div className="container pt-4 d-flex" style={{ height: 'calc(100vh - 332px)'}}>
            <div className="mx-auto align-self-center position-relative">
                <div className="spinner-border fs-1" style={{ width: '6rem', height: '6rem', color: '#F0C851' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <i className="fas fa-calculator fa-3x position-absolute top-50 start-50 translate-middle" style={{ color: '#2B62FF' }}></i>
            </div>
        </div>
    );
};

LoadingSpinner.propTypes = {
    isPublic: PropTypes.bool
};

export {
    LoadingSpinner
};