import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Button} from 'react-bootstrap';
import Slider from 'rc-slider';
import {ARTIFICIAL_DELAY, AVAILABLE_CURRENCIES, CALCULATOR_LABELS, CONTROL_STATE} from 'settings';
import useNotifications from 'hooks/useNotifications';
import calculatorService from 'services/calculatorService';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {handleRender} from 'components/HandleRender';
import { ConfirmDeleteModal } from '../../../components/modals/ConfirmDeleteModal';
import { Switch } from 'components/Switch';
import useValidatedField from '../../../hooks/useValidatedField';
import validationUtilities from '../../../utilities/validationUtilities';
import {TextBox} from '../../../components/fields/TextBox';
import {RichTextArea} from '../../../components/fields/RichTextArea';

const EditCalculator = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { showToast, showMessage } = useNotifications();
    const [pageState, setPageState] = useState(CONTROL_STATE.LOADING);
    const [saving, setSaving] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    
    const [name, setName, nameErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [bannerTitle, setBannerTitle, bannerTitleErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [pageTitle, setPageTitle, pageTitleErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [strapLine, setStrapLine, strapLineErrors] = useValidatedField('', [validationUtilities.isHtmlRequired]);
    const [ancillary, setAncillary] = useState(50);
    const [supporting, setSupporting] = useState(50);
    const [detractors, setDetractors] = useState(50);
    const [isDefault, setIsDefault] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [isWindowed, setIsWindowed] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [hasJobRole, setHasJobRole] = useState(false);
    const [currencyISOCode, setCurrencyISOCode] = useState('USD');
    const [languages, setLanguages] = useState([]);
    const [languageCode, setLanguageCode] = useState('en-GB');
    const [organisationId, setOrganisationId] = useState();
    const [themes, setThemes] = useState([]);
    const [primary, setPrimary] = useState('#2B62FF');
    const [secondary, setSecondary] = useState('#F0C851');

    const loadLanguages = async () => {
        const response = calculatorService.getLanguages();
        const data = await response;
        setLanguages(data);
    };

    const loadThemes = async () => {
        const response = calculatorService.getThemes();
        const data = await response;
        setThemes(data);
    };
    
    const loadCalculator = async () => {
        const response = calculatorService.getCalculator(id);
        const data = await response;
        setName(data.name);
        setBannerTitle(data.bannerTitle);
        setPageTitle(data.pageTitle);
        setStrapLine(data.strapLine);
        setAncillary(data.ancillary);
        setSupporting(data.supporting);
        setDetractors(data.detractors);
        setIsDefault(data.isDefault);
        setIsPublic(data.isPublic);
        setIsPublished(data.isPublished);
        setIsWindowed(data.isWindowed);
        setIsManager(data.isManager);
        setHasJobRole(data.hasJobRole);
        setCurrencyISOCode(data.currencyISOCode);
        setLanguageCode(data.languageCode);
        setOrganisationId(data.organisationId);
    };

    const saveCalculator = async () => {
        const response = await calculatorService.updateCalculator(id, {
            id,
            name,
            bannerTitle,
            pageTitle,
            strapLine,
            ancillary,
            supporting,
            detractors,
            isPublic,
            isPublished,
            isManager,
            isWindowed,
            hasJobRole,
            currencyISOCode,
            languageCode,
            organisationId: organisationId && organisationId.length > 0 ? organisationId : null
        });
        await response;
    };
    
    const downloadData = async () => {
        const response = await calculatorService.downloadData(id);
        await response;
    };
    
    const deleteData = async () => {
        const response = await calculatorService.deleteResults(id);
        await response;
        showToast('Success', 'Data has been deleted');
        setShowConfirmDelete(false);
    };

    const submitClick = async (e) => {
        e.preventDefault();
        
        if (nameErrors.length + bannerTitleErrors.length + pageTitleErrors.length + strapLineErrors.length > 0) {
            showMessage('Validation failed', 'Please fill in all required fields before submitting.');
            return;
        }
        
        try {
            setSaving(true);
            await saveCalculator();
            showToast('Success', 'Calculator Updated');
            navigate('/admin');
        } catch (error) {
            //errorUtils.logError(error);
            // TODO: Format errors
            //showMessage('There was a problem with the last request', `<p>The server returned the following message:</p><p>${error.status} - ${error.title}</p>`);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        let disable = false;

        const totalErrors = [
            nameErrors.length,
            bannerTitleErrors.length,
            pageTitleErrors.length,
            strapLineErrors.length
        ].reduce((a, b) => a + b);

        if (totalErrors > 0) {
            disable = true;
        }

        if (name.length === 0 || bannerTitle.length === 0 || pageTitle.length === 0 || strapLine.length === 0) {
            disable = true;
        }

        setDisableSubmit(disable || saving);
        
    }, [name, bannerTitle, pageTitle, strapLine, saving]);

    useEffect(() => {
       
        if (!organisationId) {
            setPrimary('#2B62FF');
            setSecondary('#F0C851');
            return;
        }

        const index = themes.findIndex(c => c.organisationId === organisationId);

        if (index < 0) {
            setPrimary('#2B62FF');
            setSecondary('#F0C851');
            return;
        }

        let item = themes[index];
        
        setPrimary(item.primary);
        setSecondary(item.secondary);

    }, [organisationId]);

    
    useEffect(() => {

        const fetchData = async () => {
            try {
                setPageState(CONTROL_STATE.LOADING);
                await Promise.all([loadCalculator(), loadLanguages(), loadThemes()]);
                setPageState(CONTROL_STATE.READY);
            } catch (error) {
                //errorUtils.logError(error);
                //errorUtils.handleStatusCode(error);
            }
        };

        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }, ARTIFICIAL_DELAY);

    }, []);
    
    if (pageState === CONTROL_STATE.LOADING) {
        return (
            <LoadingSpinner isPublic={false} />
        );
    }

    return (
        <>
            <div className="container">
                <div className="str-card my-4 p-4">
                    <h2 className="h3 mb-4">
                        Edit Calculator
                    </h2>
                    { 
                        isDefault 
                            ? 
                            <Alert variant={'warning'}>
                                As this is the default calculator, some settings may be disabled.
                            </Alert>
                            :
                            null
                    }
                    <div className="pt-4 row">
                        <div className="col-12 col-lg-9 order-last order-lg-first">
                            <div className="mb-4 row">
                                <label htmlFor="name" className="col-12 col-lg-3 col-form-label">
                                    Calculator Name
                                </label>
                                <div className="col-12 col-lg-9">
                                    <TextBox value={name} setValue={setName} errors={nameErrors} />
                                </div>
                            </div>
                            <div className="mb-4 row">
                                <label htmlFor="bannerTitle" className="col-12 col-lg-3 col-form-label">
                                    Banner Title
                                </label>
                                <div className="col-12 col-lg-9">
                                    <TextBox value={bannerTitle} setValue={setBannerTitle} errors={bannerTitleErrors} />
                                </div>
                            </div>
                            <div className="mb-4 row">
                                <label htmlFor="pageTitle" className="col-12 col-lg-3 col-form-label">
                                    Page Title
                                </label>
                                <div className="col-12 col-lg-9">
                                    <TextBox value={pageTitle} setValue={setPageTitle} errors={pageTitleErrors} />
                                </div>
                            </div>
                            <div className="mb-4 row">
                                <label htmlFor="strapLine" className="col-12 col-lg-3 col-form-label">
                                    Calculator Strapline
                                </label>
                                <div className="col-12 col-lg-9">
                                    <RichTextArea value={strapLine} setValue={setStrapLine} errors={strapLineErrors} />
                                </div>
                            </div>
                            
                            <div className="mb-4 row">
                                <label htmlFor="staticEmail" className="col-12 col-lg-3 col-form-label">
                                    Currency Prefix
                                </label>
                                <div className="col-12 col-lg-9">
                                    <select className="form-select" value={currencyISOCode} onChange={(e) => setCurrencyISOCode(e.target.value)}>
                                        {AVAILABLE_CURRENCIES.map((currency, index) => (
                                            <option key={index} value={currency.code}>
                                                {currency.code} - {currency.symbol}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 row">
                                <label htmlFor="staticEmail" className="col-12 col-lg-3 col-form-label">
                                    Language
                                </label>
                                <div className="col-12 col-lg-9">
                                    <select className="form-select" value={languageCode} onChange={(e) => setLanguageCode(e.target.value)}>
                                        {languages.map((l, index) => (
                                            <option key={index} value={l.languageCode}>
                                                {l.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 row">
                                <label htmlFor="staticEmail" className="col-12 col-lg-3 col-form-label">
                                    Theme
                                </label>
                                <div className="col-12 col-lg-9">
                                    <select className="form-select" value={organisationId} onChange={(e) => setOrganisationId(e.target.value)} disabled={isDefault}>
                                        <option value="">
                                            None
                                        </option>
                                        {themes.map((t, index) => (
                                            <option key={index} value={t.organisationId}>
                                                {t.organisationName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 row">
                                <label htmlFor="published" className="col-12 col-lg-3 col-form-label">
                                    
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <div className="d-inline-block me-2" style={{ width: 100, height: 100, backgroundColor: primary}}>

                                    </div>
                                    <div className="d-inline-block me-2" style={{ width: 100, height: 100, backgroundColor: secondary}}>

                                    </div>
                                </div>
                            </div>
                            
                            <div className="row mb-2">
                                <label htmlFor="published" className="col-12 col-lg-3 col-form-label">
                                    Published
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <Switch
                                        checked={isPublished}
                                        setChecked={setIsPublished}
                                        disabled={isDefault}                                        
                                    />
                                </div>
                            </div>
                            
                            <div className="row mb-2">
                                <label htmlFor="isPublic" className="col-12 col-lg-3 col-form-label">
                                    Public
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <Switch
                                        checked={isPublic}
                                        setChecked={setIsPublic}
                                        disabled={isDefault}
                                    />
                                </div>
                            </div>
                            
                            <div className="row mb-2">
                                <label htmlFor="isWindowed" className="col-12 col-lg-3 col-form-label">
                                    Windowed
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <Switch
                                        checked={isWindowed}
                                        setChecked={setIsWindowed}
                                        disabled={false}
                                    />
                                </div>
                            </div>

                            <div className="row mb-2">
                                <label htmlFor="isManager" className="col-12 col-lg-3 col-form-label">
                                    Manager
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <Switch
                                        checked={isManager}
                                        setChecked={setIsManager}
                                        disabled={false}
                                    />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <label htmlFor="isManager" className="col-12 col-lg-3 col-form-label">
                                    Has Job Role
                                </label>
                                <div className="col-12 col-lg-9 pt-2">
                                    <Switch
                                        checked={hasJobRole}
                                        setChecked={setHasJobRole}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            
                            <div className="mb-4">
                                <p className="m-0 text-muted">
                                    <i className="fas fa-info-circle"/> {isPublic ? 'Submit button will be hidden. Results are collected silently when values are changed.' : 'Submit button will be visible. Results are only collected when submitted.'}
                                </p>
                                <p className="m-0 text-muted">
                                    <i className="fas fa-info-circle"/> {isWindowed ? 'Header and footer will be hidden (suitable for iFrame).' : 'Header and footer will be visible (suitable for fullscreen).'}
                                </p>
                                <p className="m-0 text-muted">
                                    <i className="fas fa-info-circle"/> {isManager ? 'Fields and their descriptions will be targeted towards managers.' : 'Fields and their descriptions will be targeted towards individuals.'}
                                </p>
                                <p className="m-0 text-muted">
                                    <i className="fas fa-info-circle"/> {hasJobRole ? 'Job Role selection will be available' : 'Job Role selection will be hidden.'}
                                </p>
                            </div>
                            
                        </div>
                        <div className="col-12 col-lg-3 order-first order-lg-last">
                            <p className="d-flex">
                                <a href="#" className="flex-grow-0 align-self-center me-2" onClick={(e) => { e.preventDefault(); downloadData(); }}>
                                    Download Data
                                </a>
                                <span className="flex-grow-1 align-self-center">
                                    <i className="fas fa-file-excel text-primary" style={{ fontSize: '1.25rem' }} onClick={() => { downloadData(); }}></i>
                                </span>                                
                            </p>
                            <p className="d-flex">
                                <a href="#" className="flex-grow-0 align-self-center me-2" onClick={(e) => { e.preventDefault(); setShowConfirmDelete(true); }}>
                                    Reset / Delete Data
                                </a>
                                <span className="flex-grow-1 align-self-center">
                                    <i className="fas fa-trash-alt text-primary cursor-pointer" style={{ fontSize: '1.25rem' }} onClick={() => { setShowConfirmDelete(true); }}></i>
                                </span>
                            </p>
                        </div>
                    </div>

                    <h4 className="mt-4 pb-2 border-bottom">
                        Calculator Configuration
                    </h4>

                    <div className="mt-4 mb-5">
                        <label htmlFor="ancillary" className="form-label">
                            Ancillary
                        </label>
                        <Slider
                            min={0}
                            max={100}
                            step={5}
                            marks={CALCULATOR_LABELS}
                            value={ancillary}
                            handleRender={handleRender}
                            trackStyle={[{ backgroundColor: '#2B62FF' }]}
                            onChange={(value) => setAncillary(Number(value))}
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="supporting" className="form-label">
                            Supporting
                        </label>
                        <Slider
                            min={0}
                            max={100}
                            step={5}
                            marks={CALCULATOR_LABELS}
                            value={supporting}
                            handleRender={handleRender}
                            trackStyle={[{ backgroundColor: '#2B62FF' }]}
                            onChange={(value) => setSupporting(Number(value))}
                        />
                    </div>
                    <div className="mb-5">
                        <label htmlFor="detractors" className="form-label">
                            Detractors
                        </label>
                        <Slider
                            min={0}
                            max={100}
                            step={5}
                            marks={CALCULATOR_LABELS}
                            value={detractors}
                            handleRender={handleRender}
                            trackStyle={[{ backgroundColor: '#2B62FF' }]}
                            onChange={(value) => setDetractors(Number(value))}
                        />
                    </div>

                    <div className="text-end">
                        <Button variant={'secondary'} className={'me-2 btn-min-width'} onClick={() => navigate('/admin')}>
                            Back
                        </Button>
                        <Button variant={'primary'} className={'btn-min-width'} onClick={submitClick} disabled={disableSubmit}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            
            <ConfirmDeleteModal 
                text={'Do you wish to delete all data for this calculator?'}
                show={showConfirmDelete} 
                onHide={() => setShowConfirmDelete(false)} 
                onSubmit={deleteData} 
            />
            
        </>
    );
};

export {
    EditCalculator
};