import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useValidatedField from 'hooks/useValidatedField';
import validationUtilities from 'utilities/validationUtilities';
import {ErrorFeedback} from 'components/ErrorFeedback';
import useNotifications from '../../hooks/useNotifications';

const SignIn = () => {

    const { signIn } = useAuth();
    const navigate = useNavigate();
    const { showMessage } = useNotifications();
    const location = useLocation();
    const from = location.state?.from || '/';
    const [email, setEmail, emailErrors] = useValidatedField('', [validationUtilities.isRequired, validationUtilities.isValidEmail]);
    const [password, setPassword, passwordErrors] = useValidatedField('', [validationUtilities.isRequired]);
    const [disableSubmit, setDisableSubmit] = useState(true);
        
    const submit = async (e) => {
        try {
            e.preventDefault();
            await signIn(email, password);
            navigate(from, { replace: true });
        } catch (ex) {
            if (ex && ex.status === 401) {
                showMessage('Authentication Failed', 'Please check your details and try again.');
            }
        }
    };
    
    useEffect(() => {
        let disable = false;
        if (email.length === 0 || emailErrors.length) {
            disable = true;
        }
        if (password.length === 0 || passwordErrors.length) {
            disable = true;
        }
        setDisableSubmit(disable);
    }, [emailErrors, passwordErrors]);

    const styles = {
        backgroundImage: 'url(/img/login.png)',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: '600px'
    };
    
    return (
        <>
            <div className="container" style={styles}>
                <div className="container" style={{ maxWidth: '400px'}}>
                    <div className="str-card my-4 p-4">
                        <form onSubmit={submit}>
                            <h2 className="h3 mb-4">
                                Sign in
                            </h2>
                            <div className="mb-4">
                                <Form.Control
                                    autoFocus={true}
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={email}
                                    isInvalid={emailErrors && emailErrors.length > 0}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <ErrorFeedback errors={emailErrors || []} />
                            </div>

                            <div className="mb-4">
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={password}
                                    isInvalid={passwordErrors && passwordErrors.length > 0}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <ErrorFeedback errors={passwordErrors || []} />
                            </div>

                            <div className="mb-4">
                                <p className="text-center m-0">
                                    <Link to="/account/forgot-password">
                                        Forgot Password
                                    </Link>
                                </p>
                            </div>
                            
                            <div className="text-center">
                                <Button type="submit" className="text-uppercase" variant={'warning'} size={'lg'} onClick={submit} disabled={disableSubmit}>
                                    Login
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};


export {
    SignIn
};