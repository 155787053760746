import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ARTIFICIAL_DELAY, CONTROL_STATE} from 'settings';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {CalculatorsTable} from './CalculatorsTable';
import {Button} from 'react-bootstrap';
import calculatorService from '../../services/calculatorService';
import useAuth from '../../hooks/useAuth';
import {CalculatorsList} from './CalculatorsList';
import useDelay from '../../hooks/useDelay';
import useNotifications from '../../hooks/useNotifications';

const AdminIndex = () => {

    const { signOut } = useAuth();
    const navigate = useNavigate();
    const { startDelay, delayEnded } = useDelay();
    const { showToast } = useNotifications();
    const [refreshing, setRefreshing] = useState(false);
    const [pageState, setPageState] = useState(CONTROL_STATE.LOADING);
    const [calculators, setCalculators] = useState([]);

    const loadCalculators = async () => {
        const response = calculatorService.getCalculators();
        const data = await response;
        //const { proposalTypes } = data;
        setCalculators(data);
    };    
    
    const refresh = async (e) => {
        try {
            e.preventDefault();
            setRefreshing(true);
            await Promise.all([startDelay(1000), loadCalculators()]);
            showToast('Success', 'Data refreshed');
        } catch (error) {
            //errorUtils.logError(error);
            //errorUtils.handleStatusCode(error);
        } finally {
            setRefreshing(false);
        }
    };

    const copyLink = async (id) => {

        const index = calculators.findIndex(c => c.id === id);

        if (index < 0) {
            return;
        }

        let calculator = calculators[index];

        if (calculator.isDefault) {
            const url = process.env.REACT_APP_CALC_URL;
            navigator.clipboard.writeText(url)
                .then(function() {
                    showToast('Success', `Calculator Url '${url}' copied to clipboard.`);
                }, function(err) {
                    showToast('Failed', err);
                });
        } else {
            const url = `${process.env.REACT_APP_CALC_URL}${calculator.hash}`;
            navigator.clipboard.writeText(url)
                .then(function() {
                    showToast('Success', `Calculator Url '${url}' copied to clipboard.`);
                }, function(err) {
                    showToast('Failed', err);
                });
        }
    };

    const downloadData = async (id) => {
        const index = calculators.findIndex(c => c.id === id);
        if (index < 0) {
            return;
        }
        const response = await calculatorService.downloadData(id);
        await response;
    };

    const togglePublish = async (id) => {
        
        const index = calculators.findIndex(c => c.id === id);
        if (index < 0) {
            return;
        }
        
        await calculatorService.togglePublished(id);

        let calculator = calculators[index];
        const newCalculators = [...calculators];
        
        if (calculator.isPublished) {
            showToast('Success', 'Calculator Unpublished');
        } else {
            showToast('Success', 'Calculator published');
        }

        calculator.isPublished = !calculator.isPublished;
        newCalculators[index] = calculator;
        setCalculators(newCalculators);
    };
    
    // Initial page load
    useEffect(() => {

        const fetchData = async () => {
            try {
                setPageState(CONTROL_STATE.LOADING);
                await Promise.all([startDelay(), loadCalculators()]);
                setPageState(CONTROL_STATE.READY);
            } catch (error) {
                if (error === 401) {
                    navigate('/account/sign-in');
                }
                //errorUtils.logError(error);
                //errorUtils.handleStatusCode(error);
            }
        };

        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }, ARTIFICIAL_DELAY);

    }, []);

    if (pageState === CONTROL_STATE.LOADING || !delayEnded) {
        return (
            <LoadingSpinner isPublic={false} />
        );
    }
    
    return (
        <>
            <div className="container">
                <div className="position-relative str-card my-4 p-4">

                    <i className="fas fa-sign-out-alt cursor-pointer position-absolute top-0 end-0 p-4" title="Sign Out" style={{ fontSize: '1.5rem' }} onClick={signOut} />

                    <h2 className="h3 mb-4">
                        Dashboard
                    </h2>
                    
                    <p>
                        The following table shows the calculator instances that you have created.
                    </p>
                    
                    <div className="text-end d-flex">
                        <span className="flex-grow-1 align-self-center py-4">
                            {refreshing ? null :
                                <a href="#" onClick={(e) => refresh(e)}>
                                    Refresh
                                </a>
                            }
                        </span>
                        <span className="flex-grow-0 align-self-center py-4 ps-2 pe-4">
                            {refreshing ?
                                <i className="fas fa-sync fa-spin" style={{ fontSize: '1.5rem' }} /> :
                                <i className="fas fa-sync cursor-pointer" onClick={(e) => refresh(e)} style={{ fontSize: '1.5rem' }} />
                            }
                        </span>
                    </div>
                    
                    <CalculatorsList calculators={calculators} />
                    <CalculatorsTable calculators={calculators} copyLink={copyLink} downloadData={downloadData} togglePublish={togglePublish} />
                    
                    <div className={'text-center py-4'}>
                        <Button variant={'warning'} size={'lg'} className={'text-uppercase'} onClick={() => navigate('/admin/calculators/add') }>
                            Create new calculator site
                        </Button>    
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export {
    AdminIndex
};