import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {PEOPLE_MIN, PEOPLE_MAX, PEOPLE_STEP} from 'settings';
import {SalesPeopleIcon} from 'components/icons/SalesPeopleIcon';
import {PlusButton} from 'components/buttons/PlusButton';
import {MinusButton} from 'components/buttons/MinusButton';
import {NumericTextBox} from 'components/fields/NumericTextbox';

const SalesPeopleCard = ({ isManager, theme, numberSalesPeople, setNumberSalesPeople }) => {

    const { t : translations } = useTranslation();
    
    const subtract = () => {
        if (numberSalesPeople <= PEOPLE_MIN) {
            setNumberSalesPeople(PEOPLE_MIN);
            return;
        }
        setNumberSalesPeople(Number(numberSalesPeople) - 1);
    };

    const add = () => {
        if (numberSalesPeople >= PEOPLE_MAX) {
            setNumberSalesPeople(PEOPLE_MAX);
            return;
        }
        setNumberSalesPeople(Number(numberSalesPeople) + 1);
    };

    const onChange = (value) => {
        value = Number(value.replace(/\D+/g,''));
        if (value <= PEOPLE_MIN) {
            setNumberSalesPeople(PEOPLE_MIN);
            return;
        }
        if (value >= PEOPLE_MAX) {
            setNumberSalesPeople(PEOPLE_MAX);
            return;
        }
        setNumberSalesPeople(value);
    };

    if (!isManager) {
        return null;
    }
    
    return (
        <div className="col">
            <div className="card h-100 mb-3" style={{ backgroundColor: '#F1F1F1' }}>
                <div className="card-body p-5 pt-2">
                    <div className="text-center">
                        <SalesPeopleIcon primaryColour={theme.primary} tertiaryColour={theme.secondary} />
                        <h4 className="fw-bold my-4">
                            {translations('Sales_People_Card_Title')}
                        </h4>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="input-group border border-1 p-1 " style={{ borderColor: '#707070', borderRadius: '0.25rem', backgroundColor: '#FFF' }}>
                                <MinusButton colour={theme.primary} onClick={subtract} />
                                <NumericTextBox value={numberSalesPeople} setValue={onChange} min={PEOPLE_MIN} max={PEOPLE_MAX} step={PEOPLE_STEP} errors={[]} />                                
                                <PlusButton colour={theme.primary} onClick={add} />
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 align-self-center">
                            <p className="m-0 ps-lg-3 pt-3 pt-xl-0">
                                {translations('Sales_People_Card_Description')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SalesPeopleCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    numberSalesPeople: PropTypes.number, 
    setNumberSalesPeople: PropTypes.func
};

export {
    SalesPeopleCard
};


