import React, { useRef, useEffect } from 'react';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

/* eslint-disable react/prop-types */
const HandleTooltip = ({value, children, visible, tipFormatter = () => `${value}` }) => {
    
    const tooltipRef = useRef();
    const rafRef = useRef(null);

    function cancelKeepAlign() {
        raf.cancel(rafRef.current);
    }

    function keepAlign() {
        rafRef.current = raf(() => {
            tooltipRef.current?.forcePopupAlign();
        });
    }

    useEffect(() => {
        if (visible) {
            keepAlign();
        } else {
            cancelKeepAlign();
        }

        return cancelKeepAlign;
    }, [value, visible]);

    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            placement="top"
            overlay={tipFormatter(value)}
            overlayInnerStyle={{ minHeight: 'auto' }}
            ref={tooltipRef}
            visible={visible}
        >
            {children}
        </Tooltip>
    );
};

export const handleRender = (node, props) => {
    return (
        <HandleTooltip value={props.value} visible={props.dragging}>
            {node}
        </HandleTooltip>
    );
};

