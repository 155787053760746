import React from 'react';
import PropTypes from 'prop-types';
import {CARD_ICON_HEIGHT, CARD_ICON_WIDTH} from 'settings';

const CustomerSuccessIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 97.27 97">
            <g>
                <path fill={primaryColour} d="M43.15,54.34c2.06,1.5,3.1,.74,2.31-1.68l-.38-1.15-2.45-7.55,5.38-3.91,2.02-1.47c2.06-1.5,1.66-2.72-.88-2.72h-9.16l-1.43-4.38s0-.01,0-.02l-.59-1.83-.43-1.33h0l-.37-1.15c-.79-2.42-2.07-2.42-2.86,0l-.37,1.15-.6,1.86-.42,1.29s0,0,0,0l-1.43,4.39h-9.15c-2.55,0-2.94,1.22-.88,2.72l1.91,1.39,5.49,3.99h0l-1.42,4.38v.02l-1.4,4.3c-.79,2.42,.25,3.18,2.31,1.68l2.58-1.87,4.83-3.51h0l6.42,4.67,.97,.72Z" />
                <path fill={primaryColour} d="M19.83,58.45c1.33,.96,2,.48,1.49-1.08l-.24-.74-1.58-4.87,3.47-2.52,1.3-.95c1.33-.96,1.07-1.75-.57-1.75h-5.9l-.92-2.82s0,0,0-.01l-.38-1.18-.28-.85h0l-.24-.74c-.51-1.56-1.34-1.56-1.84,0l-.24,.74-.39,1.2-.27,.83h0l-.92,2.83H6.42c-1.64,0-1.9,.79-.57,1.75l1.23,.9,3.54,2.57h0l-.92,2.82h0s-.9,2.79-.9,2.79c-.51,1.56,.16,2.05,1.49,1.08l1.66-1.21,3.11-2.26h0l4.14,3.01,.63,.46Z" />
                <path fill={primaryColour} d="M33.28,62.22h-4.61l-.72-2.21h0s-.3-.93-.3-.93l-.22-.67h0l-.19-.58c-.4-1.22-1.05-1.22-1.44,0l-.19,.58-.3,.94-.21,.65h0l-.72,2.21h-4.61c-1.28,0-1.48,.62-.45,1.37l.97,.7,2.77,2.01h0l-.72,2.21h0s-.7,2.18-.7,2.18c-.4,1.22,.13,1.6,1.17,.85l1.3-.94,2.43-1.77h0l3.24,2.35,.5,.36c1.04,.75,1.56,.37,1.17-.85l-.19-.58-1.24-3.81,2.71-1.97,1.02-.74c1.02-.74,.82-1.36-.47-1.36Z" />
                <path fill={primaryColour} d="M51.73,62.22h-4.61l-.72-2.21h0l-.21-.65-.3-.94-.19-.58c-.4-1.22-1.04-1.22-1.44,0l-.19,.58h0l-.22,.67-.3,.92s0,0,0,0l-.72,2.21h-4.61c-1.28,0-1.48,.62-.45,1.37l1.02,.74,2.71,1.97-1.24,3.81-.19,.58c-.4,1.22,.13,1.6,1.17,.85l.49-.36,3.24-2.35h0l2.43,1.77,1.3,.94c1.04,.75,1.56,.37,1.17-.85l-.7-2.17h0s-.72-2.22-.72-2.22h0l2.77-2.01,.97-.7c1.03-.76,.83-1.38-.46-1.38Z" />
                <path fill={primaryColour} d="M64.86,46.54h-5.9l-.92-2.83h0l-.27-.83-.39-1.2-.24-.74c-.51-1.56-1.34-1.56-1.84,0l-.24,.74h0l-.28,.85-.38,1.18s0,0,0,.01l-.92,2.82h-5.9c-1.64,0-1.9,.79-.57,1.75l1.3,.95,3.47,2.52-1.58,4.87-.24,.74c-.51,1.56,.16,2.05,1.49,1.08l.63-.46,4.14-3.01h0l3.11,2.26,1.66,1.21c1.33,.96,2,.48,1.49-1.08l-.9-2.77h0s-.92-2.84-.92-2.84h0l3.54-2.57,1.23-.9c1.32-.97,1.07-1.75-.58-1.75Z" />
                <path fill={secondaryColour} d="M85.43,45.47c0,4.5-3.65,8.15-8.15,8.15-4.5,0-8.15-3.65-8.15-8.15,0-4.5,3.65-8.15,8.15-8.15,4.5,0,8.15,3.65,8.15,8.15Z" />
                <path fill={secondaryColour} d="M77.7,55.32c-.14,0-.28,0-.43,0s-.28,0-.43,0c-8.01,0-14.5,6.49-14.5,14.5v2.51h29.85v-2.51c0-8.01-6.49-14.5-14.5-14.5h0Z" />
            </g>
        </svg>
    );
};

CustomerSuccessIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    CustomerSuccessIcon
};