import React, {useState} from 'react';
import { Header } from 'components/Header';

const Unpublished = () => {
    
    const bannerTitle = 'Unpublished';
    const pageTitle = 'Sorry this calculator is not available.';

    const [theme, setTheme] = useState({
        organisationId: '',
        logo: '',
        primary: '#2B62FF',
        primaryText: '#FFFFFF',
        secondary: '#F0C851',
        secondaryText: '#FFFFFF',
        themeHeaderBgColour: '#20243F',
        themeHeaderColour: '#FFFFFF',
        themeBgColour: '#FFFFFF',
        themeColour: '#333333',
        themeFooterBgColour: '#20243F',
        themeFooterColour: '#FFFFFF',
        coreColour: '#559B84',
        coreTextColour: '#FFFFFF',
        detractorColour: '#949494',
        detractorTextColour: '#FFFFFF',
        supportingColour: '#2B62FF',
        supportingTextColour: '#FFFFFF',
        ancillaryColour: '#F0C851',
        ancillaryTextColour: '#333333'
    });


    return (
        <>
            <Header title={bannerTitle} theme={theme} />

            <div className="container">

                <div className="str-card position-relative my-4 p-4">

                    <h1 className="fw-bold pe-5">
                        {pageTitle}
                    </h1>

                </div>
            </div>
        </>
    );
};

export {
    Unpublished
};