import stringUtilities from 'utilities/stringUtilities';
import {httpHeaders, httpHeadersJson} from './httpHeaders';

const baseUrl = process.env.PUBLIC_URL;

export const httpClient = {
    get,
    post,
    postJson,
    put,
    putJson,
    delete: _delete,
    deleteJson
};

// helper functions
export function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleTextResponse(response) {
    return response.text().then(text => {
        const data = text;
        if (!response.ok) {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export function handleJsonResponse(response) {

    return response.text().then(text => {

        if (!response.ok) {
            const error = response.status;
            return Promise.reject(error);
        }
        
        const data = text && JSON.parse(text);

        if (response.ok) {
            return data;
        }

        const error = {
            title: data && data.title,
            message: data && data.detail,
            status: data && data.status || response.statusText,
            type: data && data.type,
            errors: data && data.errors
        };

        switch (response.status) {
            case 400:
                error.message = stringUtilities.formatErrors(data);
                return Promise.reject(error);
            default:
                return Promise.reject(error);
        }

    });
}

export function handleBlobResponse(response) {

    if (!response.ok) {
        const error = response.statusText;
        return Promise.reject(error);
    }
    
    let filename = 'file';

    const disposition = response.headers.get('Content-Disposition');
    filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    filename = filename.split(/;(.+)/)[0];
    filename = filename.replace(/['"]/g, '');

    response.blob().then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        // append the element to the dom, otherwise it won't work in Firefox
        document.body.appendChild(a); 
        a.click();
        // afterwards, remove the element
        a.remove();   
    });
}

export function handleError(error) {
    if (error.data) {
        return error.data;
    }
    return error;
}

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: httpHeaders()
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

function post(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: httpHeaders(),
        body: data
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

function postJson(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: httpHeadersJson(),
        body: JSON.stringify(data)
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

function put(url, data) {
    const requestOptions = {
        method: 'PUT',
        headers: httpHeaders(),
        body: data
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

function putJson(url, data) {
    const requestOptions = {
        method: 'PUT',
        headers: httpHeadersJson(),
        body: JSON.stringify(data)
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: httpHeaders(),
        body: data
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}

function deleteJson(url, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: httpHeadersJson(),
        body: JSON.stringify(data)
    };
    return fetch(`${baseUrl}/${url}`, requestOptions);
}