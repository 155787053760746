import React from 'react';
import PropTypes from 'prop-types';

const AccountManagementIcon = ({ primaryColour, secondaryColour, size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 101.65 97">
            <g>
                <path fill={secondaryColour} d="M83.76,53.13c0,4.24-3.44,7.68-7.69,7.68s-7.68-3.44-7.68-7.69c0-4.24,3.44-7.68,7.69-7.68,4.24,0,7.69,3.44,7.69,7.69Z" />
                <path fill={secondaryColour} d="M76.48,62.42c-.13,0-.27,0-.4,0s-.27,0-.4,0c-7.55,0-13.67,6.12-13.67,13.67v2.36h28.14v-2.36c0-7.55-6.12-13.67-13.67-13.67Z" />
                <path fill={primaryColour} d="M69.89,32.02H13.32c-1.38,0-2.5,1.12-2.5,2.5v9.82c1.88,.98,3.8,1.85,5.78,2.62,6.62,2.54,13.6,4.02,20.68,4.38v-3.27c0-.79,.64-1.42,1.43-1.43h5.81c.79,0,1.42,.64,1.43,1.43v3.2c8.74-.62,17.3-2.82,25.26-6.49,.39-.22,.79-.41,1.2-.58v-9.68c0-1.38-1.12-2.5-2.5-2.5h-.01Z" />
                <path fill={primaryColour} d="M44.51,59.96h-5.81c-.79,0-1.42-.64-1.43-1.43v-4.33c-9.18-.41-18.18-2.67-26.46-6.64v23.48c0,1.34,1.09,2.44,2.44,2.44H59.74s1.48-5.62,4.05-8.19,7.1-3.95,7.1-3.95c0,0-4.64-2.66-4.64-9.47,0-.7,.11-1.39,.32-2.06-6.64,2.4-13.59,3.86-20.64,4.32v4.4c0,.78-.64,1.42-1.42,1.43Z" />
                <path fill="none" d="M37.27,51.34v2.86c1.21,.06,2.46,.09,3.73,.1,1.69,0,3.33-.06,4.93-.16v-2.86c-1.6,.11-3.24,.17-4.93,.17-1.28,0-2.52-.04-3.73-.1Z" />
                <path fill="none" d="M44.51,46.65h-5.81c-.79,0-1.42,.64-1.43,1.43v3.27c1.21,.06,2.46,.1,3.73,.1,1.69,0,3.33-.06,4.93-.17v-3.2c0-.78-.64-1.42-1.43-1.42Z" />
                <path fill="none" d="M37.27,54.2v4.33c0,.79,.64,1.42,1.43,1.43h5.81c.79,0,1.42-.64,1.43-1.43v-4.4c-1.6,.1-3.24,.16-4.93,.16-1.27,0-2.52-.03-3.73-.1Z" />
                <path fill={primaryColour} d="M41.6,24.56c5.36,0,8.08,1.88,8.08,5.58h4.56c0-6.35-4.73-10.15-12.65-10.15s-12.65,3.79-12.65,10.15h4.56c0-3.7,2.72-5.58,8.08-5.58Z" />
            </g>
        </svg>
    );
};

AccountManagementIcon.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    size: PropTypes.number
};

export {
    AccountManagementIcon
};