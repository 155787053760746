import React from 'react';

/* eslint-disable react/prop-types */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error: error.toString()
        };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToServices(error, errorInfo.componentStack);
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <p>
                    Component failed with the following error: {this.state.error}
                </p>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;