import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import {ATTAINMENT_MIN, ATTAINMENT_MAX, ATTAINMENT_STEP, ATTAINMENT_LABELS, ATTAINMENT_LABELS_SHORT} from 'settings';
import {handleRender} from 'components/HandleRender';
import {useTranslation} from 'react-i18next';

const TargetAttainmentCard = ({ isManager, theme, targetAttainment, setTargetAttainment}) => {

    const { t : translations } = useTranslation();
    
    const description = isManager
        ? translations('Target_Attainment_Card_Public')
        : translations('Target_Attainment_Card_Private');
    
    const [labels, setLabels] = useState(window.innerWidth <= 800 ? ATTAINMENT_LABELS_SHORT : ATTAINMENT_LABELS);
    
    useEffect(() => {
        
        // Handler to call on window resize
        function handleResize() {
            //console.log(window.innerWidth);
            // Set window width/height to state
            if (window.innerWidth <= 800) {
                setLabels(ATTAINMENT_LABELS_SHORT);
            } else {
                setLabels(ATTAINMENT_LABELS);
            }
        }
        
        // Add event listener
        window.addEventListener('resize', handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
        
    }, []); // Empty array ensures that effect is only run on mount
    
    return (
        <div className="mb-5 p-3">
            <h4 className="text-center fw-bold my-4">
                {description}
            </h4>
            <div>
                <Slider min={ATTAINMENT_MIN}
                    max={ATTAINMENT_MAX}
                    step={ATTAINMENT_STEP}
                    marks={labels}
                    value={targetAttainment}
                    handleRender={handleRender}
                    trackStyle={[{ backgroundColor: theme.primary }]}
                    handleStyle={[{ backgroundColor: theme.secondary }]}
                    railStyle={{ backgroundColor: '#ccc' }}
                    onChange={(value) => setTargetAttainment(value)}
                />
            </div>
        </div>
    );
};

TargetAttainmentCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    targetAttainment: PropTypes.number,
    setTargetAttainment: PropTypes.func
};

export {
    TargetAttainmentCard
};

