import {httpClient, handleJsonResponse} from './httpClient';

const API_URL = 'api/v1/calculatorResults';

const getCalculatorResult = (id) => {
    return httpClient.get(`${API_URL}/${id}`)
        .then(handleJsonResponse);
};

const submitResult = (id, data) => {
    return httpClient.putJson(`${API_URL}/${id}/submit`, data)
        .then(handleJsonResponse);
};

export default {
    getCalculatorResult,
    submitResult
};