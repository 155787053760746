import React, {useEffect, useState} from 'react';
import Slider from 'rc-slider';
import {SALES_MIN, SALES_MAX, SALES_STEP, SALES_LABELS, SALES_LABELS_SHORT} from 'settings';
import {handleRender} from 'components/HandleRender';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const TargetCard = ({ isManager, theme, target, setTarget, currencySymbol }) => {

    const { t : translations } = useTranslation();
    
    const description = isManager
        ? translations('Target_Card_Public') 
        : translations('Target_Card_Private');
    
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    
    const [marks, setMarks] = useState(window.innerWidth <= 800 ? SALES_LABELS_SHORT : SALES_LABELS);
    
    useEffect(() => {
        if (windowSize.width <= 800) {
            setMarks(
                {
                    100000: currencySymbol + SALES_LABELS_SHORT['100000'],
                    2500000: currencySymbol + SALES_LABELS_SHORT['2500000'],
                    5000000: currencySymbol + SALES_LABELS_SHORT['5000000'],
                    7500000: currencySymbol + SALES_LABELS_SHORT['7500000'],
                    10000000: currencySymbol + SALES_LABELS_SHORT['10000000'],
                }
            );
        } else {
            setMarks(
                {
                    100000: currencySymbol + SALES_LABELS['100000'],
                    2500000: currencySymbol + SALES_LABELS['2500000'],
                    5000000: currencySymbol + SALES_LABELS['5000000'],
                    7500000: currencySymbol + SALES_LABELS['7500000'],
                    10000000: currencySymbol + SALES_LABELS['10000000'],
                }
            );
        }
    }, [currencySymbol, windowSize]);

    useEffect(() => {
        
        // Handler to call on window resize
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        
        // Add event listener
        window.addEventListener('resize', handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
        
    }, []);
    
    return (
        <div className="mb-5 p-3">
            <h4 className="text-center fw-bold my-4">
                {description}
            </h4>
            <div>
                <Slider 
                    min={SALES_MIN}
                    max={SALES_MAX}
                    step={SALES_STEP}
                    marks={marks}
                    value={target}
                    handleRender={handleRender}
                    trackStyle={[{ backgroundColor: theme.primary }]}
                    handleStyle={[{ backgroundColor: theme.secondary }]}
                    railStyle={{ backgroundColor: '#ccc' }}
                    onChange={(value) => setTarget(value)}
                />
            </div>
        </div>
    );
};

TargetCard.propTypes = {
    isManager: PropTypes.bool,
    theme: PropTypes.object,
    target: PropTypes.number,
    setTarget: PropTypes.func,
    currencySymbol: PropTypes.string
};

export {
    TargetCard
};
